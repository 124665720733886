import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMaterialsComponent } from '../filter-materials/filter-materials.component';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { lastValueFrom, tap } from 'rxjs';
import { getProjectIdOnLocal, updateEditedStep } from '@core/utils';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';

@Component({
  selector: 'app-rand-d-table',
  templateUrl: './rand-d-table.component.html',
  styleUrls: ['./rand-d-table.component.scss'],
  providers: [DialogService],
  // encapsulation: ViewEncapsulation.None
})
export class RandDTableComponent implements OnInit {

  @Output() updateExpiredItems = new EventEmitter<any>();
  @Output() ingredientsDone = new EventEmitter<any>();
  @Output() updateTotalPercentage = new EventEmitter<any>();
  ref: DynamicDialogRef | undefined;

  @Input()
  canUpdate = false

  @Input()
  canFillScraps:boolean = false;

  currentIndex=0;
  chocolateName='';

  showMaterialsError = false
  showPercentageError = false
  showOverweightScrapsError = false
  showRequiredFieldsError = false
  retrievingMaterials = false
  /**
   * The table headers list.
   */
  columns: any[] = [
    { name: 'Actions' },
    { name: 'Type' },
    { name: 'Code' },
    { name: 'Description' },
    { name: 'UMB' },
    { name: '%' },
    { name: '$/KG Formula' },
    { name: 'Cost/UMB' },
    { name: 'Currency' },
    { name: 'Supplier' },
    { name: 'Category' },
    { name: 'Overweight(%)' },
    { name: 'Scrap(Kg)' },
    { name: 'Scrap($)' }
  ]

  materialOptions = [
    {
      label: 'Materia Prima 1',
      value: 'Materia Prima 1'
    },
    {
      label: 'Materia Prima 2',
      value: 'Materia Prima 2'
    },
    {
      label: 'Chocolate',
      value: 'Chocolate',
    }
  ];
  materialOptionsWithOutChocolate = [
    {
      label: 'Materia Prima 1',
      value: 'Materia Prima 1'
    },
    {
      label: 'Materia Prima 2',
      value: 'Materia Prima 2'
    },
    {
      label: 'Chocolate',
      value: 'Chocolate',
      disabled: true
    }
  ];

  maxLengthInputController=2;
  chocolateSelected = false;
  editOverweight = true

  materialsList: any[]=[]

  lastSubtotalValues: any={}

  materialsListInitialBackup:any = undefined

  /**
   * The row with the form.
   */
  public tableForm: FormGroup;

  subtotal: number = 0;

  totalPercentage: any =0

  projectId=""

  sales_volume=0

  initial=true
  dolarTRM=0
  euro=0

  constructor(
    private formBuilder: FormBuilder,
    private rand_dService:RandDService,
    private _decimalPipe: DecimalPipe,
    public dialogService: DialogService,
    private macroeconomicsService: MacroeconomicsService,
    private projectBriefService:ProjectBriefService,
    private macroeconomics:MacroeconomicsService
    ) {
    // this.columns = [];
    this.tableForm = this.formBuilder.group({
      form_array: this.formBuilder.array([
        // this.formBuilder.group(this.generateFormGroupFields())
      ])
    });
  }

  async ngOnInit() {
    this.columns = this.canFillScraps?[
      { name: 'Type' },
      { name: 'Code' },
      { name: 'Description' },
      { name: 'UMB' },
      { name: '%' },
      { name: '$/KG Formula' },
      { name: 'Overweight(%)' },
      { name: 'Scrap(Kg)' },
      { name: 'Scrap($)' }
    ]
    :[
      { name: 'Actions' },
      { name: 'Type' },
      { name: 'Code' },
      { name: 'Description' },
      { name: 'UMB' },
      { name: '%' },
      { name: '$/KG Formula' },
      { name: 'Cost/UMB' },
      { name: 'MOQ' },
      { name: 'Currency' },
      { name: 'Supplier' },
      { name: 'Category' },
      { name: 'Overweight(%)' },
      { name: 'Scrap(Kg)' },
      { name: 'Scrap($)' }
    ]
    updateEditedStep(false)
    await this.getProjectId()
    await this.getDollarTRM()
    this.getSalesVolume()
    this.setMaterials()
  }

  async getDollarTRM(){
    let macroeconomicsInfoOnLocal = this.macroeconomicsService.getMacroeconomicsValues()
    if (macroeconomicsInfoOnLocal==null) {
      let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(this.projectId)
      if (macroeconomicsInfoFromApi!=null) {
        this.dolarTRM = macroeconomicsInfoFromApi.dolar || 0
        this.euro = macroeconomicsInfoFromApi.euro || 0
      }
    } else {
      this.dolarTRM= +(macroeconomicsInfoOnLocal.currencies.dolar || 0)
      this.euro= +(macroeconomicsInfoOnLocal.currencies.euro || 0)

    }
  }

  getSalesVolume(){
    this.sales_volume= (+this.projectBriefService.getCurrentProjectBriefValues().basic_info.sales_volume)||0
  }

  async getProjectId(){
    this.projectId = getProjectIdOnLocal()
  }

  async setMaterials(){

    let materialsListFromApi = await this.getMaterialList(this.projectId);
    if (this.canFillScraps && materialsListFromApi.length==0) {
      return
    }
    if(materialsListFromApi.length==0){
       this.addNewRow()

    } else {
      this.materialsListInitialBackup = materialsListFromApi
      for (let index = 0; index < materialsListFromApi.length; index++) {
        const element = materialsListFromApi[index];
        if (element.typeRawMaterial=="Chocolate") {
          this.chocolateSelected = true;
          await this.addNewRow()
          this.materialsList[index].newRow = false
          await this.getChocolateInfo(index, element.materialSap)

        } else if(element.typeRawMaterial=="Materia Prima 1" && !element.chocolate && !this.chocolateSelected){
          await this.addNewRow()
          this.materialsList[index].newRow = false
          await this.getMateriaPrima1Info(index, element.codigoSap);
          // if(element.percentage!=null){
            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              percentage: element.percentage,
            });
            // await this.calculatePrices(index)
            await formControls.patchValue({
              scrap: element.scrap_percentage??0,
              overweight: element.overweight_percentage??0,
            });
            this.addScrap(index)
            await this.calculatePrices(index)
            this.addOverweightInitial(index)
          // }
        } else if(element.typeRawMaterial=="Materia Prima 2" && !element.chocolate && !this.chocolateSelected){
          await this.addNewRow()
            this.materialsList[index].newRow = false
            await this.getMateriaPrima2Info(index, element.codigoSap);
              let formArray = this.getFormArray().controls;
              let formControls = formArray[index]
              await formControls.patchValue({
                percentage: element.percentage??0,
              });
            // await this.calculatePrices(index)
              await formControls.patchValue({
                scrap: element.scrap_percentage??0,
                overweight: element.overweight_percentage??0,
                moq: element.cantidad_min_reg_info
              });
              this.addScrap(index)
            await this.calculatePrices(index)
              this.addOverweightInitial(index)


        } else if (element.typeRawMaterial=="Materia Prima 1" && element.chocolate){
          if(element.overweight_percentage){
            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              overweight: element.overweight_percentage,
            });
            this.addOverweightInitial(index)
          }
          if(element.scrap_percentage){

            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              scrap: element.scrap_percentage
            });
            this.addScrap(index)
          }
        } else if (element.typeRawMaterial=="Materia Prima 2" && element.chocolate){
          if(element.overweight_percentage){

            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            this.editOverweight = false
            await formControls.patchValue({
              overweight: element.overweight_percentage,
              moq: element.cantidad_min_reg_info
              // scrap: element.scrap_percentage
            });
            this.editOverweight = true
            this.addOverweightInitial(index)
            // this.addScrap(index)
          }
          if(element.scrap_percentage){
            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              scrap: element.scrap_percentage
            });
            this.addScrap(index)
          }
        } else if(element.typeRawMaterial=="Materia Prima 1" && !element.chocolate && this.chocolateSelected){
          await this.addNewRow()
          this.materialsList[index].newRow = false
          await this.getMateriaPrima1Info(index, element.codigoSap);

            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              percentage: element.percentage,
            });
            // await this.calculatePrices(index)
            await formControls.patchValue({
              overweight: element.overweight_percentage??0,
              scrap: element.scrap_percentage??0
            });
            this.addScrap(index)
            await this.calculatePrices(index)
            this.addOverweightInitial(index)

        } else if(element.typeRawMaterial=="Materia Prima 2" && !element.chocolate && this.chocolateSelected){
          await this.addNewRow()
          this.materialsList[index].newRow = false
          await this.getMateriaPrima2Info(index, element.codigoSap);
            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            await formControls.patchValue({
              percentage: element.percentage??0,
              moq: element.cantidad_min_reg_info
            });
            // await this.calculatePrices(index)
            await formControls.patchValue({
              overweight: element.overweight_percentage??0,
              scrap: element.scrap_percentage??0,
            });
            this.addScrap(index)
            await this.calculatePrices(index)
            this.addOverweightInitial(index)


        }
        }
    }
    this.materialsListInitialBackup = undefined
    this.initial = false
    this.ingredientsDone.emit(false);
  }

  async getRand_DInfo(projectID:any){
    return await lastValueFrom(this.rand_dService.getRandDInfo(projectID).pipe(
      (tap( response => {

      }

      ))
        ))

  }

  async getMaterialList(projectId:any){
    let rAnd_DInfoFromAPI = await this.getRand_DInfo(projectId)
    if (rAnd_DInfoFromAPI!=null) {
      if (this.canFillScraps) {
        let Rand_DValue = {
          isNewFormula:rAnd_DInfoFromAPI.isNewFormula,
          basic_info: {
            cacaoPerRecipe: rAnd_DInfoFromAPI.cacaoPerRecipe,
            cacaoFat: rAnd_DInfoFromAPI.cacaoFat,
            milkFat: rAnd_DInfoFromAPI.milkFat,
          },
          comments:rAnd_DInfoFromAPI.comments,
          materials_list:rAnd_DInfoFromAPI.materials_list,
          userAssignedId: rAnd_DInfoFromAPI.userAssignedId
        }
        this.rand_dService.saveRand_DValuesOnLocalStorage(Rand_DValue)
      }
      return rAnd_DInfoFromAPI.materials_list
    }
    return []
  }

  showFilterPopUp(index:number) {
    this.materialsList[index].newRow=false
    this.currentIndex = index;
    this.ref = this.dialogService.open(FilterMaterialsComponent, {
        data: {
          chocolateSelected: this.chocolateSelected
        },
        showHeader: false,
        styleClass: 'filter-materials-popup-dialog',
        //header: 'Select a Product'
    });

    this.ref.onClose.subscribe(async (result: any) =>{
      if (result.length>0)  {
        if (result[0]==undefined) {
          if (this.materialsList[index].typeRawMaterial=='' || this.materialsList[index].typeRawMaterial==null || this.materialsList[index].sapCode=='NO COD' || this.materialsList[index].sapCode=='') {
            this.materialsList[index].newRow=true
          }
        } else {
          //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          // await this.removeRow(index)
          // await this.addNewRow()
          this.getRawMaterialInfo(index, result[0], result[1])
          this.chocolateName = result[2]
        }


      } else {
        if (this.materialsList[index].typeRawMaterial=='' || this.materialsList[index].typeRawMaterial==null || this.materialsList[index].sapCode=='NO COD' || this.materialsList[index].sapCode=='') {
          this.materialsList[index].newRow=true
        }
      }
    })
  }


  /**
   * Generates the form fields to be added to the form array.
   *
   * @private
   */
  public generateFormGroupFields(): Object {
    return this.canFillScraps?{
      raw_material_type: '',
      code: '',
      description: '',
      umb: '',
      percentage: '',
      $_per_kg_formula: '0',
      overweight: '',
      scrap: '',
      scrap_result: ''
    }:
    {
      raw_material_type: '',
      code: '',
      description: '',
      umb: '',
      percentage: '',
      $_per_kg_formula: '0',
      cost_per_kg: '0',
      moq: '',
      currency: '',
      supplier: '',
      category: '',
      overweight: '',
      scrap: '',
      scrap_result: ''
    };
  }

  public generateFormGroupFieldsWithValues(
    code: string,
    description: string,
    raw_material_type: string,
    category: string,
    percentage: string,
    cost_per_kg: string,
    supplier: string,
    umb: string,
    currency: string,
    $_per_kg_formula: string,
    overweight: string,
    scrap: string,
    scrap_result: string,
    moq: any
  ): Object {
    return this.canFillScraps?{
      raw_material_type: raw_material_type,
      code: code,
      description: description,
      umb: umb,
      percentage: percentage,
      $_per_kg_formula: $_per_kg_formula,
      overweight: overweight,
      scrap: scrap,
      scrap_result: scrap_result
    }:{
      raw_material_type: raw_material_type,
      code: code,
      description: description,
      umb: umb,
      percentage: percentage,
      $_per_kg_formula: $_per_kg_formula,
      cost_per_kg: cost_per_kg,
      moq: moq,
      currency: currency,
      supplier: supplier,
      category: category,
      overweight: overweight,
      scrap: scrap,
      scrap_result: scrap_result
    };
  }

  /**
   * Gets the form array.
   */
  public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row to the table.
   */
  async addNewRow() {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    const formGroup = this.formBuilder.group(this.generateFormGroupFields());

    await this.getFormArray().push(formGroup);
    await this.materialsList.push({
      nombre_proveedor:"",
      valido_de:"",
      status_reg_info:"",
      dias_entrega_reg_info:"",
      cantidad_min_reg_info:0,
      precio_neto:0,
      reg_info:"",
      por:"",
      inco1:"",
      inco2:"",
      factorKg:0,
      factor:0,
      cantidad_escala:0,
      importe_escala:0,
      ump:"",
      moneda:"",
      nombreMaterial:"",
      categoria:"",
      subcategoria:"",
      toolbox:true,
      typeRawMaterial:"",
      chocolate: false,
      newRow:true
    })
    this.checkMaterialsQuantity()
  }

  refreshTypeRawMaterial(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let typeRawMaterial = formControls.value.raw_material_type
    let sapCode:string = formControls.value.code
    this.refreshNewRowValuesToMaterialList(index, formControls)
    this.materialsList[index].typeRawMaterial = typeRawMaterial
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
    // if (typeRawMaterial!="" && sapCode.length>6 && this.materialsList[index].newRow) {
    if (typeRawMaterial!="" && sapCode.length>0 &&this.materialsList[index].newRow) {
      this.materialsList[index].newRow = false
      setTimeout( () => {
        this.getRawMaterialInfo(index, sapCode, typeRawMaterial)}, 300);

      // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
    }

  }

  updateValuesInNewRow(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let typeRawMaterial = formControls.value.raw_material_type
    this.refreshNewRowValuesToMaterialList(index, formControls)
    this.materialsList[index].typeRawMaterial = typeRawMaterial
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
  }

  async updateValuesInNewRowAndCalculate(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let typeRawMaterial = formControls.value.raw_material_type
    this.refreshNewRowValuesToMaterialList(index, formControls)
    this.materialsList[index].typeRawMaterial = typeRawMaterial
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
    await this.calculatePrices(index)
  }

  refreshNewRowValuesToMaterialList(index:number, formControls:any){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    // let formArray = this.getFormArray().controls;
    // let formControls = formArray[index]
    let typeRawMaterial = formControls.value.raw_material_type
    let category = formControls.value.category
    let percentage = formControls.value.percentage
    let description = formControls.value.description
    let supplier = formControls.value.supplier
    let currency = formControls.value.currency
    let moq = formControls.value.moq
    let precio_neto = formControls.value.cost_per_kg
    let overweight_percentage = formControls.value.overweight_percentage
    let scrap_percentage = formControls.value.scrap_percentage
    let sapCode:string = formControls.value.code
    let umb = formControls.value.umb

    this.materialsList[index].typeRawMaterial = typeRawMaterial
    this.materialsList[index].txt_gr_articulo = category
    this.materialsList[index].percentage = percentage
    this.materialsList[index].nombre = description
    this.materialsList[index].supplier = supplier
    this.materialsList[index].moneda = currency
    this.materialsList[index].precio_neto = precio_neto!=""?(+precio_neto):precio_neto
    this.materialsList[index].overweight_percentage = overweight_percentage
    this.materialsList[index].scrap_percentage = scrap_percentage
    this.materialsList[index].sapCode = sapCode
    this.materialsList[index].ump = umb

  }

  searchRawMaterial(index:number){
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let sapCode:string = formControls.value.code
    this.refreshNewRowValuesToMaterialList(index, formControls)
    let typeRawMaterial = formControls.value.raw_material_type
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
    if (typeRawMaterial!="" && sapCode.length>6 && this.materialsList[index].newRow) {
      this.materialsList[index].newRow = false
      setTimeout( () => {
        this.getRawMaterialInfo(index, sapCode, typeRawMaterial)}, 300);

      // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
    }
  }


  async updateMOQ(index:number){
    const formArray = await this.getFormArray().controls;
    const formControls = formArray[index]
    const moq = await formControls.value.moq
    this.materialsList[index].cantidad_min_reg_info = +(moq||0)
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
  }

  public addNewRowWithValues(
    code: string,
    description: string,
    raw_material_type: string,
    category: string,
    percentage: string,
    cost_per_kg: string,
    supplier: string,
    umb: string,
    currency: string,
    $_per_kg_formula: string,
    overweight: string,
    scrap: string,
    scrap_result: string,
    moq: any
  ): void {
      const formGroup = this.formBuilder.group(
        this.generateFormGroupFieldsWithValues(
          code,
          description,
          raw_material_type,
          category,
          percentage,
          cost_per_kg,
          supplier,
          umb,
          currency,
          $_per_kg_formula,
          overweight,
          scrap,
          scrap_result,
          moq
        )
      );
      this.getFormArray().push(formGroup);
  }


  /**
   * Calculates the subtotal.
   */
  // TODO: Calculate this.

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    if (this.materialsList.length>0) {
      let materialInfo = this.materialsList[index]
      let indexOfMaterialsToRemove:any = []
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let currentCostKgPrice = this.convertNumberWithCommasToNumber(formControls.value.$_per_kg_formula)
      let currentpercentage = +formControls.value.percentage
      this.subtotal=this.subtotal-currentCostKgPrice;
      this.totalPercentage=Math.round((this.totalPercentage-currentpercentage)*1000)/1000;
      if(materialInfo.typeRawMaterial=='Chocolate'){
        this.chocolateSelected = false
        for (let indexFor = 0; indexFor < this.materialsList.length; indexFor++) {
          const element = this.materialsList[indexFor];
          if (element.chocolate) {
            indexOfMaterialsToRemove.push(indexFor)
          }
        }
        await this.materialsList.splice(indexOfMaterialsToRemove[0], indexOfMaterialsToRemove.length)
        await this.getFormArray().controls.splice(indexOfMaterialsToRemove[0], indexOfMaterialsToRemove.length);
        await this.getFormArray().controls.splice(index, 1);
        await this.materialsList.splice(index, 1)
        this.chocolateSelected=false;

      } else {
        await this.getFormArray().controls.splice(index, 1);
        await this.materialsList.splice(index, 1)
      }
      let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
      if (rAndDValues) {
        rAndDValues.materials_list = this.materialsList;
        this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
      }
    this.checkMaterialsQuantity()
      this.updateExpiredItems.emit();
      return;
    }

    await this.getFormArray().controls.splice(index, 1);
    await this.materialsList.splice(index, 1)
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);

  }

  getRawMaterialInfo(index:number, sapCode:string, typeRawMaterial:string){
    if(typeRawMaterial=='Chocolate'){
      this.getChocolateInfo(index, sapCode)
      this.materialsList[index].newRow = false
      this.chocolateSelected=true;
    } else if(typeRawMaterial=='Materia Prima 2'){
      this.getMateriaPrima2Info(index, sapCode);
    } else {
      this.getMateriaPrima1Info(index, sapCode);
    }
  }

  async getChocolateBySapCode(chocolateCode:any){

    return await lastValueFrom(this.rand_dService.getChocholateBySapCode(chocolateCode).pipe(
      (tap( response => {

      }

      ))
        ))



  }

  async getRawMaterialOneForChocolate(materialSap:any){

    return await lastValueFrom(this.rand_dService.getMateriaPrima1ForChocolate(materialSap).pipe(
    (tap( response => {
    }

    ))
      ))
  }

  async getRawMaterialTwoForChocolate(materialSap:any){

    return await lastValueFrom(this.rand_dService.getMateriaPrima2ForChocolate(materialSap).pipe(
    (tap( response => {
    }

    ))
      ))
  }

  async setMateriasPrimas2ForChocolate(materiasPrimas2:any){
    if (this.materialsListInitialBackup!=undefined) {
      for (let material of this.materialsListInitialBackup) {
        if (material.typeRawMaterial == "Materia Prima 2" && material.newRow!=true && material.chocolate == true) {
          this.addNewRowWithValues(
            material.sapCode ,
            material.nombreMaterial,
            material.typeRawMaterial,
            material.txt_gr_articulo,
            this._decimalPipe.transform(0, '1.3-3')!,
            this._decimalPipe.transform(Math.round(material.precio_neto), '1.0-0')!,
            material.nombre_proveedor,
            material.ump,
            material.moneda,
            "0",
            "",
            "",
            "",
            material.cantidad_min_reg_info,
          )
          this.materialsList.push(material)
        }
      }
    } else {
      for (let item of materiasPrimas2) {
        let materiaPrima2Data= {
          nombre_proveedor:"",
          valido_de:"",
          status_reg_info:"",
          dias_entrega_reg_info:"",
          cantidad_min_reg_info:0,
          precio_neto:0,
          reg_info:"",
          por:"1",
          inco1:"",
          inco2:"",
          factorKg:0,
          factor:0,
          cantidad_escala:0,
          importe_escala:0,
          ump:"",
          moneda:"",
          nombreMaterial:"",
          categoria:"",
          subcategoria:"",
          toolbox:true,
          typeRawMaterial:"Materia Prima 2",
          chocolate: true,
          valido_hasta:"",
          overweight_percentage:0,
          sapCode:"",
          txt_gr_articulo:""
        };
        // this.rand_dService.getMateriaPrima2BySapCode(item.codigoSap).subscribe(
          await lastValueFrom(this.rand_dService.getMateriaPrima2BySapCode(item.codigoSap).pipe(
          (
            tap( response => {
              let allMateriaPrima2Data = response;
              let registerId: any;
              let mostRecentValidSince:any = this.rand_dService.getRecentRawMaterial(allMateriaPrima2Data)
              if (typeof mostRecentValidSince == "string") {
                allMateriaPrima2Data = [allMateriaPrima2Data[+mostRecentValidSince]];
              } else {
                allMateriaPrima2Data = allMateriaPrima2Data.filter((x: any) => x.valido_de == mostRecentValidSince);
                if (allMateriaPrima2Data.length > 0) {
                  registerId = allMateriaPrima2Data[0].reg_info;
                }
                allMateriaPrima2Data = allMateriaPrima2Data.filter((x: any) => x.reg_info == registerId);
              }
              materiaPrima2Data = allMateriaPrima2Data[0]
              if (materiaPrima2Data.ump === 'G') {
                materiaPrima2Data.precio_neto =
                // ((materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0)));
                ((materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0))) * 1000;
              } else {
                materiaPrima2Data.precio_neto =
                (materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0));
              }
  
              materiaPrima2Data.typeRawMaterial = "Materia Prima 2";
              materiaPrima2Data.factorKg = item.factorKg;
              materiaPrima2Data.valido_hasta = item.validez;
              materiaPrima2Data.sapCode = item.codigoSap;
  
              try {
                let indexInBackUpList = this.materialsListInitialBackup.findIndex((x:any) => x.reg_info ===materiaPrima2Data.reg_info);
                materiaPrima2Data.overweight_percentage = this.materialsListInitialBackup[indexInBackUpList].overweight_percentage??0
                materiaPrima2Data.cantidad_min_reg_info = this.materialsListInitialBackup[indexInBackUpList].cantidad_min_reg_info??0
  
              } catch (error) {
                materiaPrima2Data.overweight_percentage = 0
  
              }
              let materiaPrima2OnLocal = this.rand_dService.getMateriaPrima2()??[]
              materiaPrima2OnLocal.push(materiaPrima2Data)
              this.rand_dService.saveMateriaPrima2OnLocalStorage(materiaPrima2OnLocal)
              this.addNewRowWithValues(
                item.codigoSap ,
                materiaPrima2Data.nombreMaterial,
                materiaPrima2Data.typeRawMaterial,
                materiaPrima2Data.txt_gr_articulo,
                this._decimalPipe.transform(0, '1.3-3')!,
                this._decimalPipe.transform(Math.round(materiaPrima2Data.precio_neto), '1.0-0')!,
                materiaPrima2Data.nombre_proveedor,
                materiaPrima2Data.ump,
                materiaPrima2Data.moneda,
                "0",
                "",//materiaPrima2Data.overweight_percentage.toString(),
                "",
                "",
                materiaPrima2Data.cantidad_min_reg_info,
              )
              materiaPrima2Data.typeRawMaterial = "Materia Prima 2";
              materiaPrima2Data.chocolate=true;
              this.materialsList.push(materiaPrima2Data)
            })
          )
          ))
      }
    }
  }

  async getMateriaPrima1BySapCode(sapCode:any){
    return await lastValueFrom(this.rand_dService.getMateriaPrima1BySapCode(sapCode).pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async getMateriaPrima2BySapCode(sapCode:any){
    return await lastValueFrom(this.rand_dService.getMateriaPrima2BySapCode(sapCode).pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async getMacroeconomicsInfo(projectId:any){
    return await lastValueFrom(this.macroeconomics.getMacroeconomicsInfo(projectId).pipe(
      (tap( response => {

      }

      ))
        ))
  }

  async materiaPrima1Prices(){
    let macroeconomicsInfoOnLocal = await this.macroeconomicsService.getMacroeconomicsValues()
    let cocoaPriceUSD = 0
    let cocoaPricePremium = 0
    let dolar = 0
    if (macroeconomicsInfoOnLocal!=null) {
      let {currencies , cocoaPrices} = await this.macroeconomicsService.getMacroeconomicsValues()
      dolar= currencies.dolar
      cocoaPriceUSD = (+cocoaPrices.cocoaPriceUSD_TON)||0
      cocoaPricePremium = (+cocoaPrices.cocoaPremiumUSD_TON)||0
    } else {
      let macroeconomicsInfoFromApi = await this.getMacroeconomicsInfo(this.projectId)
      if (macroeconomicsInfoFromApi!=null) {
        dolar = macroeconomicsInfoFromApi.dolar
        cocoaPriceUSD = (+macroeconomicsInfoFromApi.cocoaPriceUSD_TON)||0
        cocoaPricePremium = (+macroeconomicsInfoFromApi.cocoaPremiumUSD_TON)||0
      }
    }
    return await lastValueFrom(this.macroeconomicsService.getPrices(dolar, cocoaPriceUSD, cocoaPricePremium).pipe(
      (tap( response => {
      }

      ))
        ))
  }

   async getChocolateInfo(index: number, sapCode:string){
    this.retrievingMaterials = true;
    let materiasPrimas1: any;
    let materiasPrimas2: any;
    let chocolateInfo: any;
    chocolateInfo = {}
    // chocolateInfo = await this.getChocolateBySapCode(sapCode)
    if(chocolateInfo!=undefined){

    chocolateInfo.materialSap = sapCode;
    chocolateInfo.typeRawMaterial = 'Chocolate';
    if (this.materialsListInitialBackup!=undefined) {
      chocolateInfo.percentage = this.materialsListInitialBackup[index].percentage??0
    }else{
      chocolateInfo.percentage = 0
    }
    //this.materialsList.push(chocolateInfo)
    this.materialsList[index] = chocolateInfo;
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    formControls.patchValue(
      {
      code: chocolateInfo.materialSap,
      description: chocolateInfo.texto,
      raw_material_type: 'Chocolate',
      category: '',
      percentage: chocolateInfo.percentage,
      $_per_kg_formula: '0',
      umb: '',
      cost_per_kg: '0',
      currency: 'COP',
      supplier: 'LUKER',
      // overweight: '',
      scrap: '',
      scrap_result: ''
      }
    )

    // this.validateChocolateConsult = true;
    materiasPrimas1 = await this.getRawMaterialOneForChocolate(chocolateInfo.materialSap)
    materiasPrimas2 = await this.getRawMaterialTwoForChocolate(chocolateInfo.materialSap)

    if (this.chocolateName!=""){
      formControls.patchValue(
        {
        description: this.chocolateName,
        }
      )
    } else if (materiasPrimas1.length) {
      formControls.patchValue(
        {
        description: materiasPrimas1[0].nombrePt,
        }
      )
    } else if(materiasPrimas2.length) {
      formControls.patchValue(
        {
        description: materiasPrimas2[0].nombrePt,
        }
      )
    }
    await this.setMateriasPrimas2ForChocolate(materiasPrimas2)

    let materiaPrima1Prices:any = {
      porcentCacao:	0,
      precioCacao:	0,
      porcentLicor: 0,
      precioLicor: 0,
      porcentCocoa:	0,
      precioCocoa:	0,
      porcentManteca:	0,
      precioManteca:	0,
      precioMantecaDeso:	0
    };
    materiaPrima1Prices = await this.materiaPrima1Prices()
    for (let item of materiasPrimas1) {
      if (item.nombre.includes('PS LICOR')) {
        item.precio_neto = Math.round(materiaPrima1Prices.precioLicor);
        item.categoria = "Licor"
      } else if (item.nombre.includes('PS MANTECA')) {
        item.precio_neto = Math.round(materiaPrima1Prices.precioManteca);
        item.categoria = "Manteca"
      } else if (item.nombre.includes('PS COCOA')) {
        item.precio_neto = Math.round(materiaPrima1Prices.precioCocoa);
        item.categoria = "Cocoa"
      } else {
        item.precio_neto = 0
      }
      item.typeRawMaterial = "Materia Prima 1"
      item.chocolate = true
      this.materialsList.push(item)
      this.addNewRowWithValues(
        item.codigoSap ,
        item.nombre,
        item.typeRawMaterial,
        item.categoria??"N/A",
        this._decimalPipe.transform(0, '1.3-3')!,
        this._decimalPipe.transform(item.precio_neto, '1.0-0')!,
        "LUKER",
        "KG",
        item.moneda,
        "0",
        "",
        "",
        "",
        ""
      )
    }
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
    await this.calculatePrices(index)
    // this.addOverweightInitial(index)
    for (let index = 0; index < this.materialsList.length; index++) {
      const element = this.materialsList[index];
      if (element.typeRawMaterial=="Materia Prima 1" && element.chocolate) {
          let formArray = this.getFormArray().controls;
          let formControls = formArray[index]
          if(this.materialsListInitialBackup!=undefined){
          } else {
            formControls.patchValue({
              overweight: 0,
              scrap: 0,
            });
            // this.addOverweightInitial(index)
            // this.addScrap(index)

          }

      } else if (element.typeRawMaterial=="Materia Prima 2" && element.chocolate){
        let formArray = this.getFormArray().controls;
          let formControls = formArray[index]
          if(this.materialsListInitialBackup!=undefined){
            // formControls.patchValue({
            //   overweight: this.materialsListInitialBackup[index].overweight_percentage??0,
            //   scrap: this.materialsListInitialBackup[index].scrap_percentage??0,
            // });
            // this.addOverweightInitial(index)
            // this.addScrap(index)
          } else {
            formControls.patchValue({
              overweight: 0,
              scrap: 0,
            });
            // this.addOverweightInitial(index)
            // this.addScrap(index)

          }
      }

    }

    // this.calculatePrices(index)
    } else {
      this.materialsList[index].newRow = true
    }
    this.retrievingMaterials = false;
    this.updateExpiredItems.emit();
    
  }

  async getMateriaPrima1Info(index:number, sapCode:string){
    let materiaPrima1Data = {
      nombre:"",
      codigoSap:"",
      factorKg:0,
      moneda:"COP",
      supplier:"LUKER",
      typeRawMaterial: "Materia Prima 1",
      chocolate: false,
      precio_neto:0,
      percentage:0,
      overweight_percentage:0,
      scrap_percentage:0,
      ump:"",
      categoria:""
    }
    this.retrievingMaterials = true;
    let materiaPrima1DataArray = await this.getMateriaPrima1BySapCode(sapCode)
    if (materiaPrima1DataArray[0]!=undefined){

      materiaPrima1Data.nombre = materiaPrima1DataArray[0].nombreComponente;
      materiaPrima1Data.codigoSap = sapCode;
      materiaPrima1Data.factorKg = materiaPrima1DataArray[0].factorKg;

      if(this.materialsListInitialBackup!=undefined){
        materiaPrima1Data.percentage =this.materialsListInitialBackup[index].percentage
        materiaPrima1Data.overweight_percentage =this.materialsListInitialBackup[index].overweight_percentage
        materiaPrima1Data.scrap_percentage =this.materialsListInitialBackup[index].scrap_percentage
      } else {
        materiaPrima1Data.percentage =0
        materiaPrima1Data.overweight_percentage =0
        materiaPrima1Data.scrap_percentage =0
      }
      let materiaPrima1Prices:any = {
        porcentCacao:	0,
        precioCacao:	0,
        porcentLicor: 0,
        precioLicor: 0,
        porcentCocoa:	0,
        precioCocoa:	0,
        porcentManteca:	0,
        precioManteca:	0,
        precioMantecaDeso: 0,
        psNibs: 0
      };

      materiaPrima1Prices = await this.materiaPrima1Prices()
      if (materiaPrima1Data.nombre.includes('PS LICOR')) {
        materiaPrima1Data.precio_neto = Math.round(materiaPrima1Prices.precioLicor);
        materiaPrima1Data.categoria = "Licor"
      } else if (materiaPrima1Data.nombre.includes('PS MANTECA') && materiaPrima1DataArray[0].componente != '2000504') {
        materiaPrima1Data.precio_neto = Math.round(materiaPrima1Prices.precioManteca);
        materiaPrima1Data.categoria = "Manteca"
      } else if (materiaPrima1Data.nombre.includes('PS COCOA')) {
        materiaPrima1Data.precio_neto = Math.round(materiaPrima1Prices.precioCocoa);
        materiaPrima1Data.categoria = "Cocoa"
      } else if (materiaPrima1Data.nombre.includes('PS NIBS')) {
        materiaPrima1Data.precio_neto = Math.round(materiaPrima1Prices.psNibs);
        materiaPrima1Data.categoria = "Nibs"
      } else if (materiaPrima1Data.nombre.includes('PS MANTECA DESODORIZADA') &&  materiaPrima1DataArray[0].componente  == '2000504') {
        materiaPrima1Data.precio_neto = Math.round(materiaPrima1Prices.precioMantecaDeso);
        materiaPrima1Data.categoria = "Manteca desodorizada"
      }

      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      this.totalPercentage = this.totalPercentage + (materiaPrima1Data.percentage??0)
      const currentPercentage = formControls.value.percentage||0
      const currentScrap = formControls.value.scrap||'0'
      const currentOverweight = formControls.value.overweight||'0'
      if(currentPercentage>0){
        this.totalPercentage = this.totalPercentage - currentPercentage
      }
      formControls.patchValue(
        {
          percentage:0,
        }
      )
      materiaPrima1Data.typeRawMaterial = "Materia Prima 1"
      materiaPrima1Data.ump = "KG"
      this.materialsList[index] = materiaPrima1Data;
      let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
      rAndDValues.materials_list = this.materialsList;
      this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
      formControls.patchValue(
        {
          code: sapCode,
          description: materiaPrima1Data.nombre,
          raw_material_type: materiaPrima1Data.typeRawMaterial,
          category: materiaPrima1Data.categoria,
          percentage: currentPercentage!=''?currentPercentage:0,
          $_per_kg_formula: '0',
          umb: materiaPrima1Data.ump,
          cost_per_kg:  this._decimalPipe.transform(materiaPrima1Data.precio_neto, '1.0-0'),
          currency: materiaPrima1Data.moneda,
          supplier: "LUKER",
          // overweight: '0',
          scrap_result: ''
        }
        )
        this.calculatePrices(index)
        formControls.patchValue(
          {
            overweight: currentOverweight!=''?currentOverweight:'0',
          }
        )
        formControls.patchValue(
          {
            scrap: currentScrap!=''?currentScrap:'0',
          }
        )
      //  formControls.patchValue({
      //   percentage: element.percentage??0,
      // });
      // await this.calculatePrices(index)
      // await formControls.patchValue({
      //   overweight: element.overweight_percentage??0,
      //   scrap: element.scrap_percentage??0,
      // });
      // this.addScrap(index)
      // this.addOverweightInitial(index)
    } else {
      if(this.materialsListInitialBackup!=undefined) {
        let formArray = await this.getFormArray().controls;
        let formControls = formArray[index]

        await formControls.patchValue(
          {
            raw_material_type:"Materia Prima 1",
            code: "NO COD",
            description: this.materialsListInitialBackup[index].nombre??"",
            category: this.materialsListInitialBackup[index].category??"",
            // percentage: this.materialsListInitialBackup[index].percentage??"0",
            // $_per_kg_formula: this.materialsListInitialBackup[index].costKgFormula??"0",
            umb: this.materialsListInitialBackup[index].umb||this.materialsListInitialBackup[index].ump||"",
            cost_per_kg:this.materialsListInitialBackup[index].precio_neto,
            currency: this.materialsListInitialBackup[index].moneda??"",
            supplier: this.materialsListInitialBackup[index].supplier??"",
            // overweight: this.materialsListInitialBackup[index].overweight_percentage??"0",
            // scrap: this.materialsListInitialBackup[index].scrap_percentage??"",
          }
          )
          let typeRawMaterial = formControls.value.raw_material_type
          let category = this.materialsListInitialBackup[index].category
          let percentage = formControls.value.percentage
          let description = formControls.value.description
          let supplier = formControls.value.supplier
          let currency = formControls.value.currency
          let precio_neto = this.materialsListInitialBackup[index].precio_neto
          let overweight_percentage = formControls.value.overweight_percentage
          let scrap_percentage = formControls.value.scrap_percentage
          let sapCode:string = formControls.value.code
          let umb:string = formControls.value.umb

          this.materialsList[index].typeRawMaterial = typeRawMaterial
          this.materialsList[index].category = category
          this.materialsList[index].percentage = percentage
          this.materialsList[index].nombre = description
          this.materialsList[index].supplier = supplier
          this.materialsList[index].moneda = currency
          this.materialsList[index].precio_neto = (+precio_neto)||0
          this.materialsList[index].overweight_percentage = overweight_percentage
          this.materialsList[index].scrap_percentage = scrap_percentage
          this.materialsList[index].sapCode = sapCode
          this.materialsList[index].ump = umb

          let rAndDValues = await this.rand_dService.getRand_DValuesOnLocalStorage();
          rAndDValues.materials_list = this.materialsList;
          this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
        // formControls.patchValue(
        //   {
        //     overweight: this.materialsListInitialBackup[index].overweight_percentage??"0",
        //     scrap: this.materialsListInitialBackup[index].scrap_percentage??"",
        //   }
        //   )

      } else {
        this.materialsList[index].sapCode="NO COD"
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue(
          {
            code: "NO COD",
          }
          )
      }

      this.materialsList[index].newRow = true
    }
    this.retrievingMaterials = false;
    this.updateExpiredItems.emit();
  }

  async getMateriaPrima2Info(index: number, sapCode:string){
    let materiaPrima2Data= {
      nombre_proveedor:"",
      valido_de:"",
      status_reg_info:"",
      dias_entrega_reg_info:"",
      cantidad_min_reg_info:0,
      precio_neto:0,
      percentage:0,
      overweight_percentage:0,
      reg_info:"",
      por:"1",
      inco1:"",
      inco2:"",
      factorKg:0,
      factor:0,
      cantidad_escala:0,
      importe_escala:0,
      ump:"",
      moneda:"",
      nombreMaterial:"",
      categoria:"",
      subcategoria:"",
      toolbox:true,
      typeRawMaterial:"Materia Prima 2",
      chocolate: false,
      valido_hasta:"",
      codigoSap:"",
      txt_gr_articulo:""
    };
    this.retrievingMaterials = true;
    let allMateriaPrima2Data = await this.getMateriaPrima2BySapCode(sapCode)
    if(allMateriaPrima2Data[0]!=undefined){

      let registerId: any;

      let mostRecentValidSince:any = this.rand_dService.getRecentRawMaterial(allMateriaPrima2Data)
      if (typeof mostRecentValidSince == "string") {
        allMateriaPrima2Data = [allMateriaPrima2Data[+mostRecentValidSince]];
      } else {
        allMateriaPrima2Data = allMateriaPrima2Data.filter((x: any) => x.valido_de == mostRecentValidSince);
        if (allMateriaPrima2Data.length > 0) {
          registerId = allMateriaPrima2Data[0].reg_info;
        }
        allMateriaPrima2Data = allMateriaPrima2Data.filter((x: any) => x.reg_info == registerId);
      }
      materiaPrima2Data = allMateriaPrima2Data[0]
      if (materiaPrima2Data.ump === 'G') {
        materiaPrima2Data.precio_neto =
          // ((materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0)));
          ((materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0))) * 1000;
      } else {
        materiaPrima2Data.precio_neto =
        (materiaPrima2Data.precio_neto / parseFloat(materiaPrima2Data.por)) * (1 + (materiaPrima2Data.factor || 0));
      }

      materiaPrima2Data.typeRawMaterial = "Materia Prima 2";
      materiaPrima2Data.chocolate=false;
      materiaPrima2Data.codigoSap = sapCode;
      materiaPrima2Data.txt_gr_articulo = allMateriaPrima2Data[0].txt_gr_articulo;
      if(this.materialsListInitialBackup!=undefined){
        materiaPrima2Data.percentage = this.materialsListInitialBackup[index].percentage
        materiaPrima2Data.overweight_percentage = this.materialsListInitialBackup[index].overweight_percentage
        materiaPrima2Data.cantidad_min_reg_info = this.materialsListInitialBackup[index].cantidad_min_reg_info
        materiaPrima2Data.precio_neto = this.materialsListInitialBackup[index].precio_neto
        materiaPrima2Data.ump = this.materialsListInitialBackup[index].ump
        materiaPrima2Data.moneda = this.materialsListInitialBackup[index].moneda
      }
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      const currentPercentage = formControls.value.percentage||0
      const currentScrap = formControls.value.scrap||'0'
      const currentOverweight = formControls.value.overweight||'0'
      formControls.patchValue(
        {
          percentage:0,
        }
        )
      this.materialsList[index] = materiaPrima2Data;
      let materiaPrima2OnLocal = this.rand_dService.getMateriaPrima2()??[]
      materiaPrima2OnLocal.push(materiaPrima2Data)
      this.rand_dService.saveMateriaPrima2OnLocalStorage(materiaPrima2OnLocal)
      let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
      rAndDValues.materials_list = this.materialsList;
      this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
      formControls.patchValue(
        {
          code: sapCode,
          description: materiaPrima2Data.nombreMaterial,
          raw_material_type: materiaPrima2Data.typeRawMaterial,
          category: materiaPrima2Data.txt_gr_articulo,
          percentage: currentPercentage!=''?currentPercentage:0,
          $_per_kg_formula: '0',
          moq: materiaPrima2Data.cantidad_min_reg_info,
          umb: materiaPrima2Data.ump,
          cost_per_kg:  materiaPrima2Data.moneda=="USD" || materiaPrima2Data.moneda=="EUR"?this._decimalPipe.transform(materiaPrima2Data.precio_neto, '1.2-2'):this._decimalPipe.transform(Math.round(materiaPrima2Data.precio_neto), '1.0-0'),
          currency: materiaPrima2Data.moneda,
          supplier: materiaPrima2Data.nombre_proveedor||"LUKER",
        // overweight: [this.materialsListInitialBackup[index]!=undefined?(this.materialsListInitialBackup[index].overweight_percentage??0):0],
        // scrap: [this.materialsListInitialBackup[index]!=undefined?this.materialsListInitialBackup[index].scrap_percentage??0:0],
        // scrap_result: ['']
        }
        )
        this.calculatePrices(index)
        formControls.patchValue(
          {
            overweight: currentOverweight!=''?currentOverweight:'0',
          }
        )
        formControls.patchValue(
          {
            scrap: currentScrap!=''?currentScrap:'0',
          }
        )
      // formControls.patchValue(
      //   {
      //   percentage: [this.materialsListInitialBackup[index]!=undefined?this.materialsListInitialBackup[index].percentage??0:this.materialsList[index].percentage]??'',
      //   }
      //   )
    } else {
      if(this.materialsListInitialBackup!=undefined) {
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]

        formControls.patchValue(
          {
            raw_material_type:"Materia Prima 2",
            code: "NO COD",
            description: this.materialsListInitialBackup[index].nombre??"",
            category: this.materialsListInitialBackup[index].txt_gr_articulo??"",
            // percentage: this.materialsListInitialBackup[index].percentage??"0",
            // $_per_kg_formula: this.materialsListInitialBackup[index].costKgFormula??"0",
            umb: this.materialsListInitialBackup[index].ump||this.materialsListInitialBackup[index].umb||"",
            moq: this.materialsListInitialBackup[index].cantidad_min_reg_info??"",
            cost_per_kg: this.materialsListInitialBackup[index].precio_neto,
            currency: this.materialsListInitialBackup[index].moneda??"",
            supplier: this.materialsListInitialBackup[index].supplier??"",
            // overweight: this.materialsListInitialBackup[index].overweight_percentage??"0",
            // scrap: this.materialsListInitialBackup[index].scrap_percentage??"",
          }
          )
          let typeRawMaterial = formControls.value.raw_material_type
          let category = formControls.value.category
          let percentage = formControls.value.percentage
          let description = formControls.value.description
          let supplier = formControls.value.supplier
          let currency = this.materialsListInitialBackup[index].moneda??""
          let precio_neto = this.materialsListInitialBackup[index].precio_neto
          let overweight_percentage = formControls.value.overweight_percentage
          let scrap_percentage = formControls.value.scrap_percentage
          let moq = formControls.value.moq
          let sapCode:string = "NO COD"
          let umb:string = formControls.value.umb

          this.materialsList[index].typeRawMaterial = typeRawMaterial
          this.materialsList[index].category = category
          this.materialsList[index].percentage = percentage
          this.materialsList[index].nombre = description
          this.materialsList[index].supplier = supplier
          this.materialsList[index].cantidad_min_reg_info = moq
          this.materialsList[index].moneda = currency
          this.materialsList[index].precio_neto = (+precio_neto)||0
          this.materialsList[index].overweight_percentage = overweight_percentage||0
          this.materialsList[index].scrap_percentage = scrap_percentage||0
          this.materialsList[index].sapCode = sapCode
          this.materialsList[index].ump = umb

          let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
          rAndDValues.materials_list = this.materialsList;
          this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
        // formControls.patchValue(
        //   {
        //     overweight: this.materialsListInitialBackup[index].overweight_percentage??"0",
        //     scrap: this.materialsListInitialBackup[index].scrap_percentage??"",
        //   }
        //   )

      } else {
        this.materialsList[index].sapCode="NO COD"
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        formControls.patchValue(
          {
            code: "NO COD",
          }
          )
      }

      this.materialsList[index].newRow= true

    }
    this.retrievingMaterials = false;
    this.updateExpiredItems.emit();
  }

  async calculatePrices(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let RawMaterialType = formControls.value.raw_material_type
    let currentCostKgPrice = this.convertNumberWithCommasToNumber((formControls.value.$_per_kg_formula||0).toString())
    if(currentCostKgPrice!=null && currentCostKgPrice!=null){
      this.subtotal= this.subtotal-currentCostKgPrice;

      if(RawMaterialType=='Chocolate'){
        let subtotalCount=0;
        let percentageChocolate = +formControls.value.percentage>100?100:+formControls.value.percentage
        if (this.totalPercentage>0 && this.initial==false) {
          this.totalPercentage = this.totalPercentage - this.materialsList[index].percentage
        }
        this.materialsList[index].percentage=percentageChocolate
        this.totalPercentage = Math.round((this.totalPercentage+ this.materialsList[index].percentage)*1000)/1000
        let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
        rAndDValues.materials_list = this.materialsList;
        this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);

        if(+formControls.value.percentage<10 && +formControls.value.percentage>1){
          this.maxLengthInputController=2
        } else {
          this.maxLengthInputController=3
        }
        if (+formControls.value.percentage>100){
          setTimeout( () => {
          formControls.patchValue({
            percentage: 100,
          });}, 500);
          percentageChocolate = +formControls.value.percentage>100?100:+formControls.value.percentage
        }
        if (percentageChocolate<=100) {
          for (let index = 0; index < this.materialsList.length; index++) {
            const item = this.materialsList[index];
            let formControlsMaterial = formArray[index]
            if ((item.typeRawMaterial === 'Materia Prima 1' || item.typeRawMaterial === 'Materia Prima 2') && item.chocolate) {
              let percentage = percentageChocolate * item.factorKg;
              const overweight = this.materialsList[index].overweight_percentage || 0
              const scraps = this.materialsList[index].scrap_percentage || 0
              this.materialsList[index].consumption_qty = (this.sales_volume * (percentage/100) * overweight ) + ( (percentage/100) * scraps )
              this.materialsList[index].percentage=this._decimalPipe.transform(percentage, '1.3-3')
              let costKg = ((item.precio_neto||0) * (percentage / 100) * (item.moneda=="USD"?this.dolarTRM:item.moneda=="EUR"?this.euro:1))
              this.materialsList[index].costKgFormula = costKg
              let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
              rAndDValues.materials_list = this.materialsList;
              this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
              formControlsMaterial.patchValue({
                percentage: this._decimalPipe.transform(percentage, '1.3-3'),
                $_per_kg_formula: this._decimalPipe.transform(Math.round(costKg + (this.materialsList[index].precio_extra_overweight??0)), '1.0-0'),
              });
              subtotalCount= subtotalCount+costKg;
          }
        }
        formControls.patchValue({
          $_per_kg_formula: this._decimalPipe.transform(Math.round(subtotalCount), '1.0-0'),
        });
        this.subtotal=this.subtotal+subtotalCount

      }
      } else if(RawMaterialType=='Materia Prima 2'){
        let percentageMateriaPrima2 = +formControls.value.percentage
        let lastPercentage = this.materialsList[index].percentage
        // if (this.totalPercentage>0 && this.materialsList[index].percentage!=percentageMateriaPrima2) {
        if (this.totalPercentage>0 && this.initial == false) {
          this.totalPercentage = this.totalPercentage - (lastPercentage??0)
          // this.totalPercentage = this.totalPercentage<0?0:this.totalPercentage
        }
        // if (this.totalPercentage>0) {
        //   this.totalPercentage = this.totalPercentage - (lastPercentage??0)
        // }
        this.materialsList[index].percentage=percentageMateriaPrima2??0
        this.totalPercentage = Math.round((this.totalPercentage+ this.materialsList[index].percentage)*1000)/1000
        const item = this.materialsList[index];
        let costKg = ((item.precio_neto||0) * (percentageMateriaPrima2 / 100) * (item.moneda=="USD"?this.dolarTRM:item.moneda=="EUR"?this.euro:1));
        this.materialsList[index].costKgFormula = costKg
        const overweight = this.materialsList[index].overweight_percentage || 0
        const scraps = this.materialsList[index].scrap_percentage || 0
        this.materialsList[index].consumption_qty = (this.sales_volume * (percentageMateriaPrima2/100) * overweight ) + ( (percentageMateriaPrima2/100) * scraps )
        let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
        rAndDValues.materials_list = this.materialsList;
        this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
        formControls.patchValue({
          $_per_kg_formula: this._decimalPipe.transform(Math.round(costKg), '1.0-0'),
        })
        this.subtotal= this.subtotal+costKg;
      } else if(RawMaterialType=='Materia Prima 1'){
        let percentageMateriaPrima1 = +formControls.value.percentage
        let lastPercentage = this.materialsList[index].percentage
        if (this.totalPercentage>0) {
          this.totalPercentage = this.totalPercentage - (lastPercentage??0)
        }
        this.materialsList[index].percentage=percentageMateriaPrima1??0
        this.totalPercentage = Math.round((this.totalPercentage + this.materialsList[index].percentage)*1000)/1000
        const item = this.materialsList[index];
        let costKg = ((item.precio_neto||0) * (percentageMateriaPrima1 / 100) * (item.moneda=="USD"?this.dolarTRM:item.moneda=="EUR"?this.euro:1))||0;
        this.materialsList[index].costKgFormula = costKg
        let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
        rAndDValues.materials_list = this.materialsList;
        this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
        formControls.patchValue({
          $_per_kg_formula: this._decimalPipe.transform(Math.round(costKg), '1.0-0'),
        })
        this.subtotal= this.subtotal+costKg;
      }
      this.subtotal=Math.round(this.subtotal)
      this.updateTotalPercentage.emit(this.totalPercentage);
    }
  }

  addOverweight(index:any){
    if (!this.editOverweight) return
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let currentCostKgPriceFormula = this.materialsList[index].costKgFormula
    let lastPricewithOverWeightExtra = currentCostKgPriceFormula + (this.materialsList[index].precio_extra_overweight??0)
    // let lastOverWeightExtra = Math.round((currentCostKgPriceFormula * (lastOverweightPercentage/100)))
    // if(lastPricewithOverWeightExtra!=currentCostKgPriceFormula){
      this.subtotal= this.subtotal-lastPricewithOverWeightExtra;
    let newOverweightPercentage = (+formControls.value.overweight) || 0
    this.materialsList[index].overweight_percentage = newOverweightPercentage
    let newOverWeightExtra = Math.round(currentCostKgPriceFormula * (newOverweightPercentage/100))
    let newPricewithOverWeightExtra=currentCostKgPriceFormula+newOverWeightExtra
    this.materialsList[index].precio_extra_overweight = newOverWeightExtra
    const scraps = this.materialsList[index].scrap_percentage || 0
    const percentage = this.materialsList[index].percentage 
    this.materialsList[index].consumption_qty = (this.sales_volume * (percentage/100) * newOverweightPercentage ) + ( (percentage/100) * scraps )
    formControls.patchValue({
        $_per_kg_formula: this._decimalPipe.transform(Math.round(newPricewithOverWeightExtra), '1.0-0'),
      })

      this.subtotal= this.subtotal+newPricewithOverWeightExtra;
      if (this.materialsList[index].chocolate) {
        for (let index = 0; index < this.materialsList.length; index++) {
          const element = this.materialsList[index];
          if (element.typeRawMaterial=="Chocolate") {
            let formArray = this.getFormArray().controls;
            let formControls = formArray[index]
            let newTotalCostPerKg = this.convertNumberWithCommasToNumber(formControls.value.$_per_kg_formula.toString())-lastPricewithOverWeightExtra+newPricewithOverWeightExtra
            formControls.patchValue({
              $_per_kg_formula: this._decimalPipe.transform(newTotalCostPerKg, '1.0-0'),
            })
          }
        }
      }
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);

  }
  addOverweightInitial(index:any){
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let currentCostKgPriceFormula = this.materialsList[index].costKgFormula
    let newOverweightPercentage = +formControls.value.overweight
    this.materialsList[index].overweight_percentage = newOverweightPercentage
    let newOverWeightExtra = Math.round(currentCostKgPriceFormula * (newOverweightPercentage/100))
    this.materialsList[index].precio_extra_overweight = newOverWeightExtra
    if (currentCostKgPriceFormula) {
      formControls.patchValue({
        $_per_kg_formula: this._decimalPipe.transform((currentCostKgPriceFormula + (this.materialsList[index].precio_extra_overweight??0)), '1.0-0'),
      })
    }
    this.subtotal= this.subtotal+(newOverWeightExtra??0);
    if (this.materialsList[index].chocolate) {
      for (let index = 0; index < this.materialsList.length; index++) {
        const element = this.materialsList[index];
        if (element.typeRawMaterial=="Chocolate") {
          let formArray = this.getFormArray().controls;
          let formControls = formArray[index]
          let newTotalCostPerKg = this.convertNumberWithCommasToNumber(formControls.value.$_per_kg_formula.toString())+(newOverWeightExtra??0)
          formControls.patchValue({
            $_per_kg_formula: this._decimalPipe.transform(newTotalCostPerKg, '1.0-0'),
          })
        }
      }
    }
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);

  }

  addScrap(index:any){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let precio_neto = Math.round(this.materialsList[index].precio_neto)
    let scrapPercentage = (+formControls.value.scrap)||0
    let materialPercentage = (+formControls.value.percentage)||0
    let scrapPrice = Math.round(scrapPercentage*materialPercentage*precio_neto*(this.materialsList[index].moneda=="USD"?this.dolarTRM:this.materialsList[index].moneda=="EUR"?this.euro:1)/100)
    this.materialsList[index].scrap_percentage = scrapPercentage
    this.materialsList[index].scrap_price = scrapPrice
    const overweight = this.materialsList[index].overweight_percentage || 0
    this.materialsList[index].consumption_qty = (this.sales_volume * (materialPercentage/100) * overweight ) + ( (materialPercentage/100) * scrapPercentage )
    formControls.patchValue({
      scrap_result: this._decimalPipe.transform(Math.round(Math.round(scrapPrice)), '1.0-0'),
    })
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
  }

  checkMaterialsQuantity(){
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    let materialsList = rAndDValues.materials_list
    if (this.initial) {
      return;
    } else if (materialsList.length<1) {
      this.showMaterialsError = true
      this.showPercentageError = false
    } else {
      this.showMaterialsError = false
      this.checkPercentage()
    }
  }

  async checkRequiredFields(){
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    let materialsList = rAndDValues.materials_list
    for (let material of materialsList) {
      const description = material.nombre || material.nombreMaterial
      const costUmb = material.precio_neto
      if (material.typeRawMaterial == "Materia Prima 1" && material.newRow==true) {
        if (description == "" || typeof costUmb == "string") {
          this.showRequiredFieldsError = true
          return false
        } 
      } else if(material.typeRawMaterial == "Materia Prima 2"){
        const MOQ = +(material.cantidad_min_reg_info||0)
        if (description == "" || typeof costUmb == "string" || !(MOQ>0)) {
          this.showRequiredFieldsError = true
          return false
        } 
      }
    }
    this.showRequiredFieldsError = false
    return true
  }

  async ErrorOverweightAndScraps(){
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    let materialsList = rAndDValues.materials_list
    if (this.initial) {
      return;
    } else if (materialsList.length>0) {
      for (let material of materialsList) {
        if (material.typeRawMaterial == 'Chocolate') continue;
        if (!(material.overweight_percentage>0) || !(material.scrap_percentage>0)) {
          this.showOverweightScrapsError = true
          return true
        }
      }
      this.showOverweightScrapsError = false
      return false
    } else {
      this.showOverweightScrapsError = false
      return false
    }
  }

  checkPercentage(){
    if (this.totalPercentage!=100) {
      this.showPercentageError = true
    } else {
      this.showPercentageError = false
    }
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  searchRawMaterialOnLostFocus(index:number) {
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let sapCode:string = formControls.value.code
    this.refreshNewRowValuesToMaterialList(index, formControls)
    let typeRawMaterial = formControls.value.raw_material_type
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    rAndDValues.materials_list = this.materialsList;
    this.rand_dService.saveRand_DValuesOnLocalStorage(rAndDValues);
    if (typeRawMaterial!="" && sapCode.length>0 && this.materialsList[index].newRow) {
      this.materialsList[index].newRow = false
      setTimeout( () => {
        this.getRawMaterialInfo(index, sapCode, typeRawMaterial)}, 300);

      // this.getRawMaterialInfo(index, sapCode, typeRawMaterial)
    }
}

convertNumberWithCommasToNumber(quantity:any){
  return parseFloat(quantity.replace(/,/g, ''))
 //  this._decimalPipe.transform(, '1.0')
 }

 async updateRandD(userId:String){
  let Rand_DValues = this.rand_dService.getRand_DValuesOnLocalStorage()
  Rand_DValues.projectId= this.projectId
  try {
    return await lastValueFrom(this.rand_dService.updateRandD(Rand_DValues, this.projectId, {user: userId}).pipe(
      (tap( response => {
      }, error => {
      }
      ))
        ))
  } catch (error) {
  }

}
}

