import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ProcessesService } from '@core/services/home/processes/processes.service';
import { ProjectBriefService } from '@core/services/home/project-brief/projectBrief.service';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { getProjectIdOnLocal, updateEditedStep } from '@core/utils';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-form-select-table',
  templateUrl: './form-select-table.component.html',
  styleUrls: ['./form-select-table.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class FormSelectTableComponent implements OnInit {
  /**
   * The table headers list.
   */
  @Input() public columns: any[];

  @Input() canUpdate = false;

  /**
   * The row with the form.
   */
  public tableForm: FormGroup;

  /**
   * The dropdown list for the select field.
   */
  public textList: any = [];

  /**
   * The dropdown list for the select field.
   */
  public psList: any[];

 @Output() processesDone = new EventEmitter<any>();


  changingPS=false;

  initial=true

  showProcessesError = false

  projectId=""

  processesList:any=[]
  processesListInitialBackup:any=undefined

  subtotal_all=0;
  subtotal_mod=0;
  subtotal_mmtto=0;
  subtotal_energy=0;
  subtotal_gas=0;
  subtotal_depreciation=0;
  subtotal_cif=0;

  rAndDValuesFromAPI:any;

  constructor(
    private formBuilder: FormBuilder,
    private processesService:ProcessesService,
    private rand_dService:RandDService,
    private _decimalPipe: DecimalPipe,
    ) {
    this.columns = [];
    // this.textList = [
    //   {
    //     label: 'Person 1',
    //     value: 'Person 1'
    //   },
    //   {
    //     label: 'Person 2',
    //     value: 'Person 2'
    //   },
    //   {
    //     label: 'Person 3',
    //     value: 'Person 3'
    //   }
    // ];
    this.psList = [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ];
    this.tableForm = this.formBuilder.group({
      form_array: this.formBuilder.array([
        
      ])
    });
  }

  async ngOnInit() {
    updateEditedStep(false)
    this.getProjectId()
    await this.getR_and_dValues()
    await this.setTextListValues()
    this.setMaterials()
    // this.gettarifas()
  }

  async getR_and_dValues(){
    let rAndDValues = this.rand_dService.getRand_DValuesOnLocalStorage();
    if (rAndDValues==null) {
      this.rAndDValuesFromAPI = await this.getRand_DInfo(this.projectId)
    } else {
      this.rAndDValuesFromAPI = rAndDValues
    }
  }
  async getRand_DInfo(projectID:any){
    return await lastValueFrom(this.rand_dService.getRandDInfo(projectID).pipe(
      (tap( response => {
        
      }

      ))
        ))

  }

  getProjectId(){
    this.projectId = getProjectIdOnLocal()  
  }
  
   async getProcessesInfo(projectID:any){
    return await lastValueFrom(this.processesService.getProcessesInfo(projectID).pipe(
      (tap( response => {
        
      }
  
      ))
        ))
  
  }

  async getProcessesList(projectId:any){
    let peocessesInfoFromAPI = await this.getProcessesInfo(projectId)

    if (peocessesInfoFromAPI!=null) {
      return peocessesInfoFromAPI.calculate_processes
    } 
    return []
  }

  async setTextListValues(){
    this.textList = await this.getRecursosFromAPI()
  }

  async setMaterials(){
    let processesListFromApi = await this.getProcessesList(this.projectId);
    if(processesListFromApi.length==0){
       this.addNewRow()
  
    } else {
      this.processesListInitialBackup = processesListFromApi
      // console.log(this.materialsListInitialBackup)
      for (let index = 0; index < processesListFromApi.length; index++) {
        const element = processesListFromApi[index];
        if (element.recurso == null) {
          continue
        }
        await this.addNewRow()
        // this.materialsList[index].newRow=false
        let formArray = this.getFormArray().controls;
        let formControls = formArray[index]
        for (let indexTextList = 0; indexTextList < this.textList.length; indexTextList++) {
          const elementTextList = this.textList[indexTextList];
          
          if (elementTextList.ceco==element.recurso.ceco) {
            
            formControls.patchValue({
              
              text: this.textList[indexTextList]
              });
              formControls.patchValue({
              
                ps: element.ps
                });
              await this.selectText(index)
              break
          }
        }
        // this.processesList[index].recurso = formControls.value.text
        // let recurso_id = formControls.value.text.id_recurso
        // let ceco = formControls.value.text.ceco
        // await this.selectText(index)
        formControls.patchValue({
          
          kg_h: element.kg_h,
          people_number: element.noPeople,
          });
          await this.calculatePrices(index)
        }
    }
    this.initial=false
    this.processesDone.emit(false);
    this.processesListInitialBackup = undefined
  }


  /**
   * Generates the form fields to be added to the form array.
   *
   * @private
   */
  public generateFormGroupFields(): Object {
    return {
      text: '',
      ps:false,
      kg_h: '',
      people_number: '',
      oee: '',
      subtotal: '',
      mod: '',
      mmtto: '',
      energy: '',
      gas: '',
      depreciation: '',
      cif: ''
    };
  }

  /**
   * Gets the form array.
   */
  public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row to the table.
   */
  async addNewRow() {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    const formGroup = this.formBuilder.group(this.generateFormGroupFields());

    this.getFormArray().push(formGroup);

    await this.processesList.push({
      text:"",
      ps:false,
      kg_h:"",
      noPeople:0,
      oee:0,
      mod:"0",
      mmtto:"0",
      energy:"0",
      gas:"0",
      depreciation:"0",
      cif:"0",
      tarifas: []
    })
    // console.log('tableForm', this.tableForm.value);
    // console.log(this.getFormArray().controls)
    this.checkProcessesQuantity()

  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  public async removeRow(index: number) {
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }

    if (this.processesList.length>0) {
      let formArray = this.getFormArray().controls;
      let formControls = formArray[index]
      let currentSubtotal_row = this.convertNumberWithCommasToNumber(formControls.value.subtotal)
      let currentSubtotal_mod = this.convertNumberWithCommasToNumber(formControls.value.mod)
      let currentSubtotal_mmtto = this.convertNumberWithCommasToNumber(formControls.value.mmtto)
      let currentSubtotal_energy =this.convertNumberWithCommasToNumber(formControls.value.energy)
      let currentSubtotal_gas = this.convertNumberWithCommasToNumber(formControls.value.gas)
      let currentSubtotal_depreciation = this.convertNumberWithCommasToNumber(formControls.value.depreciation)
      let currentSubtotal_cif = this.convertNumberWithCommasToNumber(formControls.value.cif)

      this.subtotal_all = this.subtotal_all - currentSubtotal_row
      this.subtotal_mod = this.subtotal_mod - currentSubtotal_mod
      this.subtotal_mmtto = this.subtotal_mmtto - currentSubtotal_mmtto
      this.subtotal_energy = this.subtotal_energy - currentSubtotal_energy
      this.subtotal_gas = this.subtotal_gas - currentSubtotal_gas
      this.subtotal_depreciation = this.subtotal_depreciation - currentSubtotal_depreciation
      this.subtotal_cif = this.subtotal_cif - currentSubtotal_cif
  
        await this.getFormArray().controls.splice(index, 1);
        await this.processesList.splice(index, 1)
  
        // console.log(this.getFormArray().controls)
        // console.log(this.materialsList)
    
      let processesValue = this.processesService.getProcessesValuesOnLocalStorage();
      if (processesValue) {
        processesValue.calculate_processes = this.processesList;
        this.processesService.saveProcessesValuesOnLocalStorage(processesValue);
      }
      // if(this.materialsListInitialBackup!=undefined){
      //   this.materialsListInitialBackup=this.materialsList
  
      // }
      return;
    }
    this.checkProcessesQuantity()
  }

  async getRecursosFromAPI(){
    return await lastValueFrom(this.processesService.getRecursos().pipe(
      (tap( response => {
        this.textList = response
      }

      ))
        ))

  }

  async getTarifasFromAPI(ceco:any){

    return await lastValueFrom(this.processesService.getTarifasByCeco(ceco).pipe(
      (tap( response => {

      }

      ))
        ))



  }

  async getOEEFromAPI(recurso_id:number){
    return await lastValueFrom(this.processesService.getOEEById(recurso_id).pipe(
      (tap( response => {
      }
      ))
        ))

  }



  async selectText(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    this.processesList[index].recurso = formControls.value.text
    let recurso_id = formControls.value.text.recursos_id
    let ceco = formControls.value.text.ceco
    let oeeInfo:any = await this.getOEEFromAPI(recurso_id);
    // let oeeInfo:any = await this.getOEEFromMock(recurso_id)
    if (oeeInfo) {
      let oeeValue = oeeInfo["valor"]??1
      this.processesList[index].oee = oeeValue==""?1:(((+oeeValue)||100)/100)
      formControls.patchValue({
        oee: (oeeValue==""?"100%":(+oeeValue.toFixed(2)).toString() + "%"),
      });
      
      let tarifasList = await this.getTarifasFromAPI(ceco);
        this.processesList[index].tarifas = tarifasList
        this.saveValuesOnLocalStorage()
    } else {
      this.processesList[index].oee = 1
      formControls.patchValue({
        oee:"100%",
      });
      let tarifasList = await this.getTarifasFromAPI(ceco);
        this.processesList[index].tarifas = tarifasList
        this.saveValuesOnLocalStorage()
    }
    // console.log("si pasa")
  }

  async calculatePrices(index:number){
    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let tarifasList = this.processesList[index].tarifas
    let formArray = this.getFormArray().controls;
    let formControls = formArray[index]
    let currentps = formControls.value.ps??false
    let chocolatePercent = 1
    let OEE = this.processesList[index].oee
    let currentKgH = (+formControls.value.kg_h)||0
    let currentpeopleQty = (+formControls.value.people_number)||0
    let recursoEnergia = this.processesList[index].recurso["energia_kw_per_h"]??0
    // let recursoEnergia = this.processesList[index].recurso.energia??0
    // let recursoGas = this.processesList[index].recurso.gas??0
    let recursoGas = this.processesList[index].recurso["gas_m3_per_h"]??0
    let currentSubtotal_row = this.convertNumberWithCommasToNumber(formControls.value.subtotal)
    let currentSubtotal_mod = this.convertNumberWithCommasToNumber(formControls.value.mod)
    let currentSubtotal_mmtto = this.convertNumberWithCommasToNumber(formControls.value.mmtto)
    let currentSubtotal_energy =this.convertNumberWithCommasToNumber(formControls.value.energy)
    let currentSubtotal_gas = this.convertNumberWithCommasToNumber(formControls.value.gas)
    let currentSubtotal_depreciation = this.convertNumberWithCommasToNumber(formControls.value.depreciation)
    let currentSubtotal_cif = this.convertNumberWithCommasToNumber(formControls.value.cif)
    let mod=0
    let mmtto=0
    let energy=0
    let gas=0
    let depreciation=0
    let cif=0
    if(currentps){
      
      for (let material of this.rAndDValuesFromAPI.materials_list) {
        if (material.typeRawMaterial=="Chocolate") {
          chocolatePercent = material.percentage/100
        }
      }
    }
    if(tarifasList.length>0){
      if(currentKgH>0 && currentpeopleQty>0){
        this.subtotal_all = this.subtotal_all - currentSubtotal_row
        this.subtotal_mod = this.subtotal_mod - currentSubtotal_mod
        this.subtotal_mmtto = this.subtotal_mmtto - currentSubtotal_mmtto
        this.subtotal_energy = this.subtotal_energy - currentSubtotal_energy
        this.subtotal_gas = this.subtotal_gas - currentSubtotal_gas
        this.subtotal_depreciation = this.subtotal_depreciation - currentSubtotal_depreciation
        this.subtotal_cif = this.subtotal_cif - currentSubtotal_cif
        for (let tarifaInfo of tarifasList) {
          
          if((tarifaInfo.actividad=="PP0001") || (tarifaInfo.actividad.toLocaleLowerCase()=="zmod")){
            mod = Math.round(this.calculateMod(OEE, currentKgH, currentpeopleQty, tarifaInfo.tarifa)*chocolatePercent)
            // console.log(mod, "mod")
          }
          if((tarifaInfo.actividad=="PP0002") || (tarifaInfo.actividad.toLocaleLowerCase()=="zman")){
            mmtto = Math.round(this.calculateMmtto(OEE, currentKgH, tarifaInfo.tarifa)*chocolatePercent)
            // // console.log(mmtto, "mmtto")
          }
          if((tarifaInfo.actividad=="PP0003") || (tarifaInfo.actividad.toLocaleLowerCase()=="zenr")){
            energy = Math.round(this.calculateEnergy(OEE, currentKgH, tarifaInfo.tarifa, recursoEnergia)*chocolatePercent)
            // console.log(energy, "energy")
          }
          if((tarifaInfo.actividad=="PP0004") || (tarifaInfo.actividad.toLocaleLowerCase()=="zgas")){
            gas = Math.round(this.calculateGas(OEE, currentKgH, tarifaInfo.tarifa, recursoGas)*chocolatePercent)
            // console.log(gas, "gas")
          }
          if((tarifaInfo.actividad=="PP0005") || (tarifaInfo.actividad.toLocaleLowerCase()=="zdep")){
            depreciation = Math.round(this.calculateDepreciation(OEE, currentKgH, tarifaInfo.tarifa)*chocolatePercent)
            // console.log(depreciation, "depreciation")
          }
          if((tarifaInfo.actividad=="PP0006") || (tarifaInfo.actividad.toLocaleLowerCase()=="zoin")){
            cif = Math.round(this.calculateCif(OEE, currentKgH, tarifaInfo.tarifa)*chocolatePercent)
            // console.log(cif, "cif")
          }
          
        }
        let newSubtotal_row = mod+mmtto+energy+gas+depreciation+cif
        this.subtotal_all = this.subtotal_all + newSubtotal_row
        this.subtotal_mod = this.subtotal_mod + mod
        this.subtotal_mmtto = this.subtotal_mmtto + mmtto
        this.subtotal_energy = this.subtotal_energy + energy
        this.subtotal_gas = this.subtotal_gas + gas
        this.subtotal_depreciation = this.subtotal_depreciation + depreciation
        this.subtotal_cif = this.subtotal_cif + cif
        formControls.patchValue({
          subtotal: this._decimalPipe.transform(newSubtotal_row, '1.0-0'),
          mod: this._decimalPipe.transform(mod, '1.0-0'),
          mmtto: this._decimalPipe.transform(mmtto, '1.0-0'),
          energy: this._decimalPipe.transform(energy, '1.0-0'),
          gas: this._decimalPipe.transform(gas, '1.0-0'),
          depreciation: this._decimalPipe.transform(depreciation, '1.0-0'),
          cif: this._decimalPipe.transform(cif, '1.0-0')
        });
        this.processesList[index].subtotal = newSubtotal_row
        this.processesList[index].mod = mod
        this.processesList[index].mmtto = mmtto
        this.processesList[index].energy = energy
        this.processesList[index].gas = gas
        this.processesList[index].depreciation = depreciation
        this.processesList[index].cif = cif
      } else {
        this.subtotal_all = this.subtotal_all - currentSubtotal_row
        this.subtotal_mod = this.subtotal_mod - currentSubtotal_mod
        this.subtotal_mmtto = this.subtotal_mmtto - currentSubtotal_mmtto
        this.subtotal_energy = this.subtotal_energy - currentSubtotal_energy
        this.subtotal_gas = this.subtotal_gas - currentSubtotal_gas
        this.subtotal_depreciation = this.subtotal_depreciation - currentSubtotal_depreciation
        this.subtotal_cif = this.subtotal_cif - currentSubtotal_cif
        formControls.patchValue({
          subtotal: 0,
          mod:mod,
          mmtto:mmtto,
          energy:energy,
          gas:gas,
          depreciation:depreciation,
          cif:cif
        });
      }
      this.processesList[index].noPeople = currentpeopleQty
      this.processesList[index].kg_h = currentKgH
      this.processesList[index].ps = currentps
      this.saveValuesOnLocalStorage()
    }
    
  }

  calculateMod(oee:number, kgH:number, peopleQty:number, tarifa:number){
    // console.log(tarifa, "tarifa")
    let modResult = (1/(kgH*oee)) * peopleQty * tarifa
    return modResult
  }
  calculateMmtto(oee:number, kgH:number, tarifa:number){
    // console.log(tarifa, "tarifa")
    let mmttoResult = (1/(kgH*oee)) * tarifa
    return mmttoResult
  }
  calculateEnergy(oee:number, kgH:number, tarifa:number, energia:number){
    let energyResult = (1/(kgH*oee)) * tarifa * energia
    return energyResult
  }
  calculateGas(oee:number, kgH:number, tarifa:number, gas:number){
    // console.log(tarifa, "tarifa")
    let gasResult = (1/(kgH*oee)) * tarifa * gas
    return gasResult
  }
  calculateDepreciation(oee:number, kgH:number, tarifa:number){
    // console.log(tarifa, "tarifa")
    let depreciationResult = (1/(kgH*oee)) * tarifa
    return depreciationResult
  }
  calculateCif(oee:number, kgH:number, tarifa:number){
    // console.log(tarifa, "tarifa")
    // let cifResult = (1/(kgH*oee)) * tarifa
    // return cifResult
    return tarifa
  }

  async saveValuesOnLocalStorage(){
    let processesValues = this.processesService.getProcessesValuesOnLocalStorage();
    processesValues.calculate_processes = this.processesList;
    this.processesService.saveProcessesValuesOnLocalStorage(processesValues);
  }

  convertNumberWithCommasToNumber(quantity:String){
    if (quantity=='') return 0
    return parseFloat(quantity.replace(/,/g, ''))
   //  this._decimalPipe.transform(, '1.0')
   }

   checkProcessesQuantity(){
    let processesValues = this.processesService.getProcessesValuesOnLocalStorage();
    let processesList = processesValues.calculate_processes 
    if (this.initial) {
      return;
    } else if (processesList.length<1) {
      this.showProcessesError = true
    } else {
      this.showProcessesError = false
    }
  }

}
