import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BusinessUnitsService } from '@core/services/home/business_units/business_units.service';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { PackagingService } from '@core/services/home/packaging/packaging.service';
import { ProcessesService } from '@core/services/home/processes/processes.service';
import { RandDService } from '@core/services/home/rand-d/rand-d.service';
import { GetUserDetail, GetUserId } from '@core/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-create-folder-popup',
  templateUrl: './create-folder-popup.component.html',
  styleUrls: ['./create-folder-popup.component.scss']
})
export class CreateFolderPopupComponent implements OnInit{

    updatingFolder = false
    newClient = false
    folderId = ''
    clientId = ''
    createUpdateFolder=false
    user_details:any={}
    business_unit = ''

    otherDollarPrice=false
    otherPoundPrice=false
    otherCocoaPrice=false
    macroeconomicsLoaded=false

    folderValuesFromApi:any={}

    clients:any=[]

    dolarValues:any=[]
    poundValues: any[]= [];
    cocoaValues:any=[]

    initial=true

    rAndDUsers:any = []
    userAssignedNameRandD = ""
    packagingUsers:any = []
    userAssignedNamePackaging = ""
    processesUsers:any = []
    userAssignedNameProcesses = ""
  
  constructor(
    private foldersService:FoldersService,
    private businessUnitsService:BusinessUnitsService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private macroeconomicsService: MacroeconomicsService,
    private packagingService: PackagingService,
    private processesService: ProcessesService,
    private randDService: RandDService,


  ){
    if (this.config.data) {
      this.updatingFolder = this.config.data.updatingFolder
      this.folderId = this.config.data.folderId,
      this.business_unit = this.config.data.business_unit
      this.clientId = this.config.data.client
    }
    this.setUserDetails()
  }
  allowCreateFolder=true

  folderValues = {
    folderId: "",
    user_id:"",
    basic_info: {
      project_name:'',
      client_name: '',
      client: '',
      executive: '',
      sales_volume:'',
      bussines_unit:''
    },
    delivery: {
      destination_country:'',
      incoterm: '',
      delivery_port: '',
      address: '',
      city: '',
    },
    macroeconomics: {
      dollar_price: 0,
      pound_price: 0,
      euro_price: 0,
      cocoa_price: 0,
      cocoa_premium: 0
    },
    users: {
      r_and_d_user: "",
      packaging_user: "",
      processes_user: ""
    },
    comments:''
  }

  selectedClient=""
  selectedClientName=""
  selectedExecutive=""
  executiveOptions:any=[]
  businessUnitOptions:any=[]

  basicInfoForm: FormGroup = new FormGroup({
    project_name: new FormControl(this.folderValues.basic_info.project_name, [Validators.required]),
    client_name: new FormControl(this.folderValues.basic_info.client_name, []),
    client: new FormControl(this.folderValues.basic_info.client, []),
    executive: new FormControl(this.folderValues.basic_info.executive, []),
    sales_volume: new FormControl(this.folderValues.basic_info.sales_volume, []),
    bussines_unit: new FormControl(this.folderValues.basic_info.bussines_unit, []),
  });

  deliveryForm:FormGroup = new FormGroup({
    destination_country: new FormControl(this.folderValues.delivery.destination_country, []),
    incoterm: new FormControl(this.folderValues.delivery.incoterm, []),
    delivery_port: new FormControl(this.folderValues.delivery.delivery_port, []),
    address: new FormControl(this.folderValues.delivery.address, []),
    city: new FormControl(this.folderValues.delivery.city, []),
  });

  usersForm:FormGroup = new FormGroup({
    rAndD: new FormControl(this.folderValues.users.r_and_d_user, []),
    packaging: new FormControl(this.folderValues.users.packaging_user, []),
    processes: new FormControl(this.folderValues.users.processes_user, []),
  });

  macroeconomicsForm:FormGroup = new FormGroup({
    dollar_price: new FormControl({value: 0, disabled: true}, []),
    pound_price: new FormControl({value: 0, disabled: true}, []),
    euro_price: new FormControl({value: 0, disabled: true}, []),
    cocoa_price: new FormControl({value: 0, disabled: true}, []),
    cocoa_premium: new FormControl({value: "", disabled: false}, []),
  });

  commentsForm:FormGroup = new FormGroup({
    comments: new FormControl("", []),
  });

  async ngOnInit() {
    if (this.business_unit!=undefined) {
      await this.setClients(this.business_unit)
    }
    await this.getExecutiveUsersFromAPI()
    await this.getBusinessUnitsFromAPI()
    if (this.updatingFolder) {
      await this.setFolderInfo()
    } else {
      this.setUsers()
      if (this.clientId) {
        this.basicInfoForm.patchValue({
          client: this.clientId
        });
        
      }
      this.initial = false
    }
    await this.setCurrenciesValues()
    await this.setCocoaValues()
  }

  changeOtherDollarPrice(){
    this.otherDollarPrice=!this.otherDollarPrice
  }
  changeOtherPoundPrice(){
    this.otherPoundPrice=!this.otherPoundPrice
  }
  changeOtherCocoaPrice(){
    this.otherCocoaPrice=!this.otherCocoaPrice
  }

  async setFolderInfo(){
    let folderValues:any = await this.getFolderInfoFromAPI()
    await this.setClients(folderValues.basic_info.bussines_unit)
    this.folderValuesFromApi = folderValues
    this.selectedClient = folderValues.basic_info.client
    this.basicInfoForm.patchValue({
      project_name: folderValues.basic_info.project_name,
      client_name: folderValues.basic_info.client_name,
      client: folderValues.basic_info.client,
      executive: folderValues.basic_info.executive,
      sales_volume: folderValues.basic_info.sales_volume,
      bussines_unit: folderValues.basic_info.bussines_unit
    });
    this.deliveryForm.patchValue({
      destination_country: folderValues.delivery.destination_country,
      incoterm: folderValues.delivery.incoterm,
      delivery_port: folderValues.delivery.delivery_port,
      address: folderValues.delivery.address,
      city: folderValues.delivery.city
    });
    this.macroeconomicsForm.patchValue({
      dollar_price: folderValues.macroeconomics!=null?folderValues.macroeconomics.dollar_price??0:0,
      pound_price: folderValues.macroeconomics!=null?folderValues.macroeconomics.pound_price??0:0,
      euro_price: folderValues.macroeconomics!=null?folderValues.macroeconomics.euro_price??0:0,
      cocoa_price: folderValues.macroeconomics!=null?folderValues.macroeconomics.cocoa_price??0:0,
      cocoa_premium: folderValues.macroeconomics!=null?folderValues.macroeconomics.cocoa_premium:0
    }, {emitEvent: false});
    this.usersForm.patchValue({
      rAndD: folderValues.users!=null?folderValues.users.r_and_d_user:"",
      packaging: folderValues.users!=null?folderValues.users.packaging_user:"",
      processes: folderValues.users!=null?folderValues.users.processes_user:"",
    });
    this.commentsForm.patchValue({
      comments: folderValues.comments,
    });
    await this.setUsers()
    this.initial=false
    this.updateValues()
    // if (folderValues!=null) {
    //   this.folderName=folderValues.basic_info.project_name
    // }
  }
  

  async getFolderInfoFromAPI(){
    return await lastValueFrom(this.foldersService.getFolderById(this.folderId).pipe(
      (tap( response => {

      }))

    ))
  }


  updateValues() {
    if (this.initial==true) {
      return;
    }
    
    let foundUser=false
    if (this.basicInfoForm.value.project_name.trim()!="") {
     this.createUpdateFolder=true
     } else {
      this.createUpdateFolder=false
    }
    for (let index = 0; index < this.executiveOptions.length; index++) {
      const element = this.executiveOptions[index];
      if(element._id==this.basicInfoForm.value.executive){
        this.selectedExecutive=element.nombre+" "+element.apellido
        foundUser=true
      }
    }
      if(!foundUser){
        this.selectedExecutive = ""
    }

    this.selectedClient = this.basicInfoForm.value.client
    this.selectedClientName = this.basicInfoForm.value.client_name
    this.folderValues = {
      folderId:"",
      user_id:"",
      basic_info: {
        project_name: this.basicInfoForm.value.project_name,
        client_name: this.basicInfoForm.value.client_name,
        client: this.basicInfoForm.value.client,
        executive: this.basicInfoForm.value.executive,
        sales_volume:this.basicInfoForm.value.sales_volume,
        bussines_unit:this.basicInfoForm.value.bussines_unit
      },
      delivery: {
        destination_country:this.deliveryForm.value.destination_country,
        incoterm: this.deliveryForm.value.incoterm,
        delivery_port: this.deliveryForm.value.delivery_port,
        address: this.deliveryForm.value.address,
        city: this.deliveryForm.value.city,
      },
      macroeconomics: {
        dollar_price: this.macroeconomicsForm.value.dollar_price,
        pound_price: this.macroeconomicsForm.value.pound_price,
        euro_price: this.macroeconomicsForm.value.euro_price,
        cocoa_price: this.macroeconomicsForm.value.cocoa_price,
        cocoa_premium: this.macroeconomicsForm.value.cocoa_premium
      },
      users: {
        r_and_d_user: this.usersForm.value.rAndD??"",
        packaging_user: this.usersForm.value.packaging??"",
        processes_user: this.usersForm.value.processes??"",
      },
      comments: this.commentsForm.value.comments
    }
    this.assignRandDUserName()
    this.assignPackagingUserName()
    this.assignProcessesUserName()
  }

  setUserDetails(){
    this.user_details = GetUserDetail()
  }

  async setClients(businessUnitId:string){
    this.clients = await this.getClients(businessUnitId)
  }

  changeBusinessUnit(){
    if (this.initial==true) {
      return;
    }
    this.basicInfoForm.patchValue({
      client_name: "",
      client: "",
    });
      this.business_unit = this.basicInfoForm.value.bussines_unit
      this.selectedClient = ""
      this.selectedClientName = ""
      this.folderValues.basic_info.client = ""
      this.folderValues.basic_info.client_name = ""
      this.setClients(this.basicInfoForm.value.bussines_unit)
  }

  async createFolder(folderData:any){
    return await lastValueFrom(this.foldersService.createFolder(folderData, {user: this.user_details._id}).pipe(
      (tap( response => {

      }, error => {
      }))

    ))
  }

  async updateFolder(folderData:any, folderId:string){
    return await lastValueFrom(this.foldersService.updateFolder(folderData, folderId, {user: this.user_details._id}).pipe(
      (tap( response => {

      }, error => {
      }))

    ))
  }

  async createFolderButton(){
    if(this.allowCreateFolder){
      this.allowCreateFolder=false
      let result = false
      try {
        this.folderValues.user_id = GetUserId()
        this.folderValues.basic_info.bussines_unit = this.business_unit
        if (this.newClient) {
          this.folderValues.basic_info.client = this.folderValues.basic_info.client_name
        } else {
          this.folderValues.basic_info.client = this.selectedClient
          this.folderValues.basic_info.client_name = await this.getClientName(this.selectedClient)
        }
        let createFolderResult = await this.createFolder(this.folderValues);
        if (createFolderResult) {
          result = true
          this.ref.close(result)
        }
      } catch (error) {
      }
      this.allowCreateFolder=true
    }

  }

  async updateFolderButton(){
    if(this.allowCreateFolder){
      this.allowCreateFolder=false
      let result = false
      try {
        this.folderValues.user_id = GetUserId()
        this.folderValues.folderId = this.folderId
        if (this.newClient) {
          this.folderValues.basic_info.client = this.folderValues.basic_info.client_name
        } else {
          this.folderValues.basic_info.client = this.selectedClient
          this.folderValues.basic_info.client_name = await this.getClientName(this.selectedClient)
        }
        let createFolderResult = await this.updateFolder(this.folderValues, this.folderId);
        if (createFolderResult) {
          let folderValues = {
            _id: this.folderId,
            name: this.folderValues.basic_info.project_name,
            client: this.folderValues.basic_info.client,
          }
          this.foldersService.saveFolderValuesOnLocalStorage(folderValues)
          result = true
          this.ref.close(result)
        }
      } catch (error) {
        this.ref.close(result)
      }
      this.allowCreateFolder=true
    }
  }

  async getExecutiveUsersFromAPI(){
    return await lastValueFrom(this.foldersService.getExecutiveUsers().pipe(
      (tap( response => {
        let foundUser=false
        this.executiveOptions = response
        
        for (let index = 0; index < this.executiveOptions.length; index++) {
          const element = this.executiveOptions[index];
          this.executiveOptions[index].nombre_completo = element.nombre+" "+element.apellido
          if(element._id==this.folderValues.basic_info.executive){
            this.selectedExecutive=element.nombre+" "+element.apellido
            foundUser=true
          }
          if(!foundUser){
            this.selectedExecutive = ""
          }
      }
    }

      ))
        ))

  }

  async getClients(businessUnit:string){
    return await lastValueFrom(this.foldersService.getClientsByBusinessunit(businessUnit).pipe(
      (tap( response => {
      }))))
  }

  async getBusinessUnitsFromAPI(){
    return await lastValueFrom(this.businessUnitsService.getAllBusinessUnits().pipe(
      (tap( response => {
        let foundUser=false
        this.businessUnitOptions = response
    }

      ))
        ))

  }

  async getDolarValuesFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getDolar().pipe(
      (tap( response => {
      }

      ))
        ))

  }
  async getDolarToPoundFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getDolarToPound().pipe(
      (tap( response => {
      }

      ))
        ))

  }
  
  async setCurrenciesValues(){
    await this.setDolarValues()
    // await this.setPoundValues()
  }
  async setDolarValues(){
    try {
      let dolarValuesFromAPI:any = await this.getDolarValuesFromAPI()
      this.macroeconomicsForm.get('dollar_price')?.enable();
      this.dolarValues.push({label: `${dolarValuesFromAPI.value} - TRM`, price: dolarValuesFromAPI.value.toString()})
      if (dolarValuesFromAPI.value != +this.macroeconomicsForm.value.dollar_price && +this.macroeconomicsForm.value.dollar_price>0) {
        this.dolarValues.push({label: `${this.macroeconomicsForm.value.dollar_price} - TRM`, price: this.macroeconomicsForm.value.dollar_price.toString()})
      }
    } catch (error) {
      this.macroeconomicsForm.get('dollar_price')?.enable();
      if (+this.macroeconomicsForm.value.dollar_price>0) {
        this.dolarValues.push({label: `${this.macroeconomicsForm.value.dollar_price} - TRM`, price: this.macroeconomicsForm.value.dollar_price.toString()})
      }
    }
    this.macroeconomicsForm.patchValue({
      dollar_price: this.folderValuesFromApi.macroeconomics!=null?this.folderValuesFromApi.macroeconomics.dollar_price.toString():0,
    }, {emitEvent: false});
  }

  async setPoundValues(){
    let poundValuesFromAPI:any={
      rates:{
        GBP:0
      }
    }
    try {
      poundValuesFromAPI = await this.getDolarToPoundFromAPI()
      this.macroeconomicsForm.get('pound_price')?.enable();
      let dolarToPound = this.dolarValues[0].price / poundValuesFromAPI.result.GBP
      this.poundValues.push({label: `${dolarToPound.toFixed(2)}`, price: dolarToPound.toFixed(2)})
      if (+dolarToPound.toFixed(2) != +this.macroeconomicsForm.value.pound_price && +this.macroeconomicsForm.value.pound_price>0) {
        this.poundValues.push({label: `${this.macroeconomicsForm.value.pound_price}`, price: this.macroeconomicsForm.value.pound_price.toString()})
      }
    } catch (error) {
      poundValuesFromAPI.rates.GBP = 0.7
      this.macroeconomicsForm.get('pound_price')?.enable();
      if (+this.macroeconomicsForm.value.pound_price>0) {
        this.poundValues.push({label: `${this.macroeconomicsForm.value.pound_price}`, price: this.macroeconomicsForm.value.pound_price.toString()})
      }
    }
    this.macroeconomicsForm.patchValue({
      pound_price: this.folderValuesFromApi.macroeconomics!=null?(this.folderValuesFromApi.macroeconomics.pound_price??0).toString():0,
    }, {emitEvent: false});

  }
  
  async getCocoaValuesFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getCocoa().pipe(
      (tap( response => {
      }

      ))
        ))
  }
  async setCocoaValues(){
    try {
      let cocoaPricesFromApi:any = await this.getCocoaValuesFromAPI()
      let keysToIterate = Object.keys(cocoaPricesFromApi)
      let currentValueExists = false
      this.macroeconomicsForm.get('cocoa_price')?.enable();
      this.macroeconomicsForm.get('cocoa_premium')?.enable();
      for (let key of keysToIterate) {
        let cleanedPrice = cocoaPricesFromApi[key]["Último"].substring(0, cocoaPricesFromApi[0]["Último"].length-1)
        if(+this.macroeconomicsForm.value.cocoa_price == +cleanedPrice) {currentValueExists=true}
        this.cocoaValues.push({label:`${cleanedPrice} ${cocoaPricesFromApi[key].Mes}` ,price: cleanedPrice.toString()})
      }
      if (!currentValueExists && this.macroeconomicsForm.value.cocoa_price>0) {
        this.cocoaValues.push({label: `${+this.macroeconomicsForm.value.cocoa_price} Cash`, price: this.macroeconomicsForm.value.cocoa_price.toString()})
      }
    } catch (error) {
      this.macroeconomicsForm.get('cocoa_premium')?.enable();
      this.macroeconomicsForm.get('cocoa_price')?.enable();
      if (this.macroeconomicsForm.value.cocoa_price>0) {
        this.cocoaValues.push({label: `${+this.macroeconomicsForm.value.cocoa_price} Cash`, price: this.macroeconomicsForm.value.cocoa_price.toString()})
      }
    }
    let currentCocoaPrice = 0
    let currentCocoaPrremium = 0
    if (this.folderValuesFromApi.macroeconomics!=null) {
      if (this.folderValuesFromApi.macroeconomics.cocoa_price!=null) {
        currentCocoaPrice = this.folderValuesFromApi.macroeconomics.cocoa_price.toString()
      }
      if (this.folderValuesFromApi.macroeconomics.cocoa_premium!=null) {
        currentCocoaPrremium = this.folderValuesFromApi.macroeconomics.cocoa_premium.toString()
      }
      
    }
    this.macroeconomicsForm.patchValue({
      cocoa_price: currentCocoaPrice,
      cocoa_premium: currentCocoaPrremium
    });
}

async setUsers(){
  this.getRAndDUsersFromAPI()
  this.getPackagingUsersFromAPI()
  this.getProcessesUsersFromAPI()
}


assignRandDUserName(){
  for (let index = 0; index < this.rAndDUsers.length; index++) {
    const element = this.rAndDUsers[index];
    if(element._id==this.folderValues.users.r_and_d_user){
      this.userAssignedNameRandD=element.nombre+" "+element.apellido
      break 
      }
    }
}

assignPackagingUserName(){
  for (let index = 0; index < this.packagingUsers.length; index++) {
    const element = this.packagingUsers[index];
    if(element._id==this.folderValues.users.packaging_user){
      this.userAssignedNamePackaging=element.nombre+" "+element.apellido
      break 
      }
    }
}

assignProcessesUserName(){
  for (let index = 0; index < this.processesUsers.length; index++) {
    const element = this.processesUsers[index];
    if(element._id==this.folderValues.users.processes_user){
      this.userAssignedNameProcesses=element.nombre+" "+element.apellido
      break 
      }
    }
}

async getPackagingUsersFromAPI(){
  return await lastValueFrom(this.packagingService.getUsers().pipe(
    (tap( response => {
      this.packagingUsers = response
      for (let index = 0; index < this.packagingUsers.length; index++) {
        const element = this.packagingUsers[index];
        this.packagingUsers[index].nombre_completo = element.nombre+" "+element.apellido
        if (!this.updatingFolder) continue;
        if(element._id==this.folderValuesFromApi.users.packaging_user){
          this.userAssignedNamePackaging=element.nombre+" "+element.apellido
          this.usersForm.patchValue(
            {
              packaging: element._id
            }
            )
          }
        }
    }

    ))
      ))

}

async getRAndDUsersFromAPI(){
  return await lastValueFrom(this.randDService.getUsers().pipe(
    (tap( response => {
      this.rAndDUsers = response
      for (let index = 0; index < this.rAndDUsers.length; index++) {
        const element = this.rAndDUsers[index];
        this.rAndDUsers[index].nombre_completo = element.nombre+" "+element.apellido
        if (!this.updatingFolder) continue;
        if(element._id==this.folderValuesFromApi.users.r_and_d_user){
          this.userAssignedNameRandD=element.nombre+" "+element.apellido
          this.usersForm.patchValue(
            {
              rAndD: element._id
            }
            )
            
          }
        }
    }

    ))
      ))

}

async getProcessesUsersFromAPI(){
  return await lastValueFrom(this.processesService.getUsers().pipe(
    (tap( response => {
      this.processesUsers = response
      for (let index = 0; index < this.processesUsers.length; index++) {
        const element = this.processesUsers[index];
        this.processesUsers[index].nombre_completo = element.nombre+" "+element.apellido
        if (!this.updatingFolder) continue;
        if(element._id==this.folderValuesFromApi.users.processes){
          this.userAssignedNameProcesses=element.nombre+" "+element.apellido
          this.usersForm.patchValue(
            {
              processes: element._id
            }
            )
          }
        }
    }

    ))
      ))

}

createNewClient(){
  this.newClient = !this.newClient;
  if (!this.newClient) {
    this.basicInfoForm.patchValue({
      client: this.selectedClient,
    });
  } else {
    this.folderValues.basic_info.client_name = this.selectedClientName
  }
}

async getClientName(clientId:string){
  const currentClient = this.clients.filter((client:any) => client._id == clientId)
  if (currentClient.legth==0) return ""
  return currentClient[0].name
}
}
