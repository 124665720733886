import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FoldersService } from '@core/services/home/folders/folders.service';
import { MacroeconomicsService } from '@core/services/home/macroeconomics/macroeconomics.service';
import { getProjectIdOnLocal, GetUserDetail, updateEditedStep } from '@core/utils';
import { Macroeconomics } from '@pages/home/macroeconomics/models/macroeconomics.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, tap } from 'rxjs';

@Component({
  selector: 'app-macroeconomics-accordion',
  templateUrl: './macroeconomics-accordion.component.html',
  styleUrls: ['./macroeconomics-accordion.component.scss'],
  providers: [MessageService, ConfirmationService]

})
export class MacroeconomicsAccordionComponent {

  @Output() updateMacroeconomicsValues = new EventEmitter<any>();

  @Input()
  canUpdate=false
  
  @Input()
  style: any ={}
  
  dolarValues: any[] = [];
  euroValues: any[] = [];
  poundValues: any[]= [];

  cocoaValues: any[] = []
  selectedDolar = '';
  selectedEuro = '';
  selectedPound = '';

  selectedMonth=''

  edited_step = false

  cacaoOrganicoPrice=0
  
  pricesLoaded:boolean = false;

  isNewMaccroeconomics:boolean = true;
  initial=true
  initial_all=true
  initial_organic=true

  success=true

  otherDollarPrice=false
  otherEuroPrice=false
  otherPoundPrice=false
  otherCocoaPrice=false

  // Table
  tableHeaders: any[] = [
    { name: 'PS Licor' },
    { name: 'PS Manteca' },
    { name: 'Cacao' },
    { name: 'PS Cocoa' },
    { name: 'PS Manteca Deso' },
    { name: 'PS Nibs' },
  ];

  tableData: any[] = [
    {
      pslicor: '0',
      psmanteca: '0',
      cacao: '0',
      pscocoa: '0',
      psmantenca_deso: '0',
      nibs: '0',
    },
  ];

  projectId:any ="";
  macroEconomicsId:any=""

  canFill=["SUPERADMIN", "ADMIN", "COMERCIAL", "RYD", "EMPAQUES", "PROCESOS"]
  allowedToFill=false
  user_details:any={}
  constructor(
    private macroeconomicsService: MacroeconomicsService,
    private _decimalPipe: DecimalPipe,
    private foldersService: FoldersService,
    private messageService: MessageService

  ) {

    this.checkUserPermissions()
  }

  async ngOnInit() {
    this.currenciesForm.get('dolar')?.valueChanges.subscribe(v => this.updateCurrencyDolar(v));
    this.currenciesForm.get('euro')?.valueChanges.subscribe(v => this.updateCurrencyEuro(v));
    this.currenciesForm.get('pound')?.valueChanges.subscribe(v => this.updateCurrencyPound(v));
    updateEditedStep(false)
    this.getProjectId()
    await this.setCacaoOrganicoPrice()
    await this.setMacroeconomicsInfo(this.projectId)
  }

  checkUserPermissions(){
    this.user_details = GetUserDetail()
    if (this.canFill.includes(this.user_details.tipo)) {
      this.allowedToFill=true
    }
  }

  getProjectId(){
    this.projectId = getProjectIdOnLocal()
  }

  isOrganicValue:boolean = false;

  macroeconomicsValues: Macroeconomics = {
    projectId:"",
    currencies:{
      euro: 0,
      dolar: 0,
      pound: 0
    },
    organic: false,
    cocoaPrices:{
      cocoaPriceUSD_TON: 0,
      cocoaPremiumUSD_TON: 0
    },
    comments:""
  }

  currenciesForm: FormGroup = new FormGroup({
    dolar: new FormControl({value: 0, disabled: true}),
    euro: new FormControl({value: 0, disabled: true}),
    pound: new FormControl({value: 0, disabled: true}),
  });

  pricesForm:FormGroup = new FormGroup({
    cocoaPriceUSD_TON: new FormControl({value: 0, disabled: true}),
    cocoaPremiumUSD_TON: new FormControl({value: 0, disabled: true}),
  });

  commentsForm:FormGroup = new FormGroup({
    comments: new FormControl("", []),
  });

  async setMacroeconomicsInfo(projectId:any){
    let macroeconomicsValuesFromAPI = await this.getMacroeconomicsInfo(projectId)
    if (macroeconomicsValuesFromAPI!=null) {
      this.isNewMaccroeconomics = false
      this.macroeconomicsValues.currencies.dolar = +macroeconomicsValuesFromAPI.dolar
      this.macroeconomicsValues.currencies.euro = +macroeconomicsValuesFromAPI.euro
      this.macroeconomicsValues.currencies.pound = +macroeconomicsValuesFromAPI.pound
      this.macroeconomicsValues.organic = macroeconomicsValuesFromAPI.organic
      this.macroeconomicsValues.comments = macroeconomicsValuesFromAPI.comments
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON = macroeconomicsValuesFromAPI.cocoaPriceUSD_TON
      this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON = macroeconomicsValuesFromAPI.cocoaPremiumUSD_TON
      this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
    } else {
      this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
      let folderId = await this.foldersService.getFolderValuesOnLocalStorage()._id
      let folderIdInfo:any = await this.getFolderInfo(folderId)
      this.macroeconomicsValues.currencies.dolar = folderIdInfo.macroeconomics!=null?folderIdInfo.macroeconomics.dollar_price:0
      this.macroeconomicsValues.currencies.euro = folderIdInfo.macroeconomics!=null?folderIdInfo.macroeconomics.euro_price:0
      this.macroeconomicsValues.currencies.pound = folderIdInfo.macroeconomics!=null?folderIdInfo.macroeconomics.pound_price:0
      
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON = folderIdInfo.macroeconomics!=null?folderIdInfo.macroeconomics.cocoa_price:0
      this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON = folderIdInfo.macroeconomics!=null?folderIdInfo.macroeconomics.cocoa_premium:0
      
    }
    this.currenciesForm.patchValue({
      dolar: +this.macroeconomicsValues.currencies.dolar,
      pound: +this.macroeconomicsValues.currencies.pound
    }, {emitEvent: false});
    let extraPriceOrganic = this.macroeconomicsValues.organic?(this.cacaoOrganicoPrice??0):0
    this.pricesForm.patchValue(
      {
          cocoaPriceUSD_TON: this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON,
          cocoaPremiumUSD_TON:  ((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0)+(extraPriceOrganic>0?(-extraPriceOrganic):0)
      }
    )
    this.commentsForm.setValue(
      {
        comments: this.macroeconomicsValues.comments
      }
      )
      this.updateComments()
    
    this.isOrganicValue=this.macroeconomicsValues.organic
    this.initial_organic=false
    await this.getPrices(
      this.macroeconomicsValues.currencies.dolar>0?this.macroeconomicsValues.currencies.dolar:this.macroeconomicsValues.currencies.pound, 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON, 
      this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON==0?extraPriceOrganic:(((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0))
    )
      
  }

  async getFolderInfo(folderId:String){
    return await lastValueFrom(this.foldersService.getFolderById(folderId).pipe(
      (tap( response => {
      }

      ))
        ))
  }

  async getMacroeconomicsInfo(projectID:any){
    return await lastValueFrom(this.macroeconomicsService.getMacroeconomicsInfo(projectID).pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async updateComments(){
    if (!this.initial && !this.initial_organic && this.canUpdate) {
      updateEditedStep(true)
    }
    this.macroeconomicsValues.comments = this.commentsForm.value.comments
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
  }



  updateCurrencyEuro(value:any){
    if (!this.initial) {
      if (this.canUpdate) {
        updateEditedStep(true)
      }
    }
     if(this.initial) {
      this.macroeconomicsValues.currencies.euro = value!=""?+(value):+(this.macroeconomicsValues.currencies.euro)
    } else {
      this.macroeconomicsValues.currencies.euro = value??0
    }
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
  }

  updateCurrencyDolar(value:any){
    if (!this.initial) {
      if (this.canUpdate) {
        updateEditedStep(true)
      }
      // if (this.dolarValues.length==1 && this.dolarValues[0].price != +this.currenciesForm.value.dolar) {
      //   this.dolarValues.push({label: `${this.currenciesForm.value.dolar} - TRM`, price: +this.currenciesForm.value.dolar})
      // } else if (this.dolarValues[0].price != +this.currenciesForm.value.dolar) {
      //   this.dolarValues[1] = {label: `${this.currenciesForm.value.dolar} - TRM`, price: +this.currenciesForm.value.dolar}
      // }
    }

    const lastDolarPrice = this.macroeconomicsValues.currencies.dolar
     if(this.initial) {
      this.macroeconomicsValues.currencies.dolar = value!=""?+(value):+(this.macroeconomicsValues.currencies.dolar)
    } else {
      this.macroeconomicsValues.currencies.dolar = value??0
    }
    if (value>0 && !this.initial) {
      this.currenciesForm.patchValue({
        pound: 0
      }, {emitEvent: false});
    }
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
    if (
      this.macroeconomicsValues.currencies.dolar!=null && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=null &&
      this.macroeconomicsValues.currencies.dolar!=undefined && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=undefined &&
      this.initial==false
      ) {
        let extraPriceOrganic = this.macroeconomicsValues.organic?(this.cacaoOrganicoPrice??0):0
        if (lastDolarPrice!=this.macroeconomicsValues.currencies.dolar) {
          this.getPrices(
            this.macroeconomicsValues.currencies.dolar, 
            this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON, 
            this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON==0 || this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON==extraPriceOrganic?extraPriceOrganic:(((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0)+extraPriceOrganic))
          }
        }
  }
  updateCurrencyPound(value:any){
    if (!this.initial) {
      if (this.canUpdate) {
        updateEditedStep(true)
      }
    }
    const lastPoundPrice = this.macroeconomicsValues.currencies.pound
    if(this.initial) {
      this.macroeconomicsValues.currencies.pound = value!=""?+(value):+(this.macroeconomicsValues.currencies.pound)
    } else {
      this.macroeconomicsValues.currencies.pound = value??0
    }
    if (value>0 && !this.initial) {
      this.currenciesForm.patchValue({
        dolar: 0
      }, {emitEvent: false});
    }
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
    if (
      this.macroeconomicsValues.currencies.pound!=null && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=null &&
      this.macroeconomicsValues.currencies.pound!=undefined && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=undefined &&
      this.initial==false
      ) {
        let extraPriceOrganic = this.macroeconomicsValues.organic?(this.cacaoOrganicoPrice??0):0
        if (lastPoundPrice!=this.macroeconomicsValues.currencies.pound) {
          this.getPrices(
            this.macroeconomicsValues.currencies.pound, 
            this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON, 
            this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON==0?extraPriceOrganic:(((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0)+extraPriceOrganic))
          }
        }
  }

  async updateCocoaPrices() {

    if (!this.initial && this.canUpdate) {
      updateEditedStep(true)
    }
    let extraPriceOrganic =  this.macroeconomicsValues.organic?(this.cacaoOrganicoPrice??0):0
    this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON = this.initial==false?+this.pricesForm.value.cocoaPriceUSD_TON:+this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON,
    this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON = this.initial==false?((+this.pricesForm.value.cocoaPremiumUSD_TON)+extraPriceOrganic):((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON))
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
    if (
      this.macroeconomicsValues.currencies.dolar!=null && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=null &&
      this.macroeconomicsValues.currencies.dolar!=undefined && 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON!=undefined &&
      this.initial==false
      ) {
      this.getPrices(
        this.macroeconomicsValues.currencies.dolar>0?this.macroeconomicsValues.currencies.dolar:this.macroeconomicsValues.currencies.pound, 
        this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON, 
        this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON==0?extraPriceOrganic:(((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0)))
    }
  }

  async changeIsOrganicValue(value:boolean){
    if ((!this.initial || !this.initial_organic) && this.canUpdate) {
      updateEditedStep(true)
    }
    if ( this.macroeconomicsValues.organic==value) return
    let extraPriceOrganic = this.cacaoOrganicoPrice
    this.macroeconomicsValues.organic=value;
    this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON = ((+this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON)??0)+(value?extraPriceOrganic:(-extraPriceOrganic))
    this.macroeconomicsService.saveMacroeconomicsValues(this.macroeconomicsValues)
    await this.getPrices(
      this.macroeconomicsValues.currencies.dolar>0?this.macroeconomicsValues.currencies.dolar:this.macroeconomicsValues.currencies.pound, 
      this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON, 
      this.macroeconomicsValues.cocoaPrices.cocoaPremiumUSD_TON
    )
  }

  
  async updateMacroeconomics(){
    try {
      return await lastValueFrom(this.macroeconomicsService.updateMacroeconomics(this.macroeconomicsValues,this.projectId, {user: this.user_details._id, wait_result: true}).pipe(
        (tap( response => {
          this.changeInfoInVersion()
        }, error => {
          this.success=false
        }
        ))
          ))
    } catch (error) {
      this.success=false
    }

  }

  async createMacroeconomics(){
    try {
      return await lastValueFrom(this.macroeconomicsService.saveMacroeconomics(this.macroeconomicsValues, {user: this.user_details._id}).pipe(
        (tap( response => {
          this.changeInfoInVersion()
        }, error => {
          this.success=false
        }
        ))
          ))
    } catch (error) {
      this.success=false
    }

  }
  async saveMacroeconomics() {
    // if (this.isNewMaccroeconomics){
      this.success=true
      this.macroeconomicsValues.projectId = this.projectId
      if (this.isNewMaccroeconomics){
        await this.createMacroeconomics()
        this.changesUpdatesPopUp()

    } else {
      // delete this.macroeconomicsValues.projectId
      await this.updateMacroeconomics()
      this.changesUpdatesPopUp()
    }
  }
      async changeInfoInVersion(){
        let infoInVersion= {
          macroeconomics_status:true
        }
        return await lastValueFrom(this.macroeconomicsService.saveMacroeconomicsVersionInfo(this.projectId,infoInVersion).pipe(
          (tap( response => {
          }
    
          ))
            ))
    
      }

  async getCostosFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getCostos().pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async setCacaoOrganicoPrice(){
    let costosFromApi:any = await this.getCostosFromAPI()

    for (let costo of costosFromApi){
      if (costo.nombreVariable=="Cacao Organico") {
        this.cacaoOrganicoPrice = costo.valor
      }
    }
  }

  async getDolarValuesFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getDolar().pipe(
      (tap( response => {
      }

      ))
        ))

  }
  async getEuroValuesFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getEuro().pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async getDolarToPoundFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getDolarToPound().pipe(
      (tap( response => {
      }

      ))
        ))

  }

  async setDolarValues(){
    let dolarValuesFromAPI = await this.getDolarValuesFromAPI()
    this.dolarValues.push({label: `${dolarValuesFromAPI.value} - TRM`, price: dolarValuesFromAPI.value.toString()})
    if (dolarValuesFromAPI.value != this.macroeconomicsValues.currencies.dolar &&  this.macroeconomicsValues.currencies.dolar>0) {
      this.dolarValues.push({label: `${this.macroeconomicsValues.currencies.dolar} - TRM`, price: this.macroeconomicsValues.currencies.dolar.toString()})
    }
  }
  async setEuroValues(){
    let euroValuesFromAPI = await this.getEuroValuesFromAPI()
    this.euroValues.push({label: `${(euroValuesFromAPI.value || euroValuesFromAPI.eur.cop).toFixed(2)}`, price: (euroValuesFromAPI.value || euroValuesFromAPI.eur.cop).toFixed(2)})
    if ((euroValuesFromAPI.value || euroValuesFromAPI.eur.cop) != this.macroeconomicsValues.currencies.euro && this.macroeconomicsValues.currencies.euro>0) {
      this.euroValues.push({label: `${this.macroeconomicsValues.currencies.euro}`, price: this.macroeconomicsValues.currencies.euro.toFixed(2)})
    }
  }

  async setPoundValues(){
    let poundValuesFromAPI:any={
      rates:{
        GBP:0
      }
    }
    try {
      poundValuesFromAPI = await this.getDolarToPoundFromAPI()
      
      let dolarToPound = this.dolarValues[0].price / poundValuesFromAPI.result.GBP
      this.poundValues.push({label: `${dolarToPound.toFixed(2)}`, price: +dolarToPound.toFixed(2)})
      if (+dolarToPound.toFixed(2) != +this.macroeconomicsValues.currencies.pound && +this.macroeconomicsValues.currencies.pound>0) {
        this.poundValues.push({label: `${this.macroeconomicsValues.currencies.pound}`, price: this.macroeconomicsValues.currencies.pound.toString()})
      }
      
    } catch (error) {
      poundValuesFromAPI.rates.GBP = 0.7
      if (+this.macroeconomicsValues.currencies.pound>0) {
        this.poundValues.push({label: `${this.macroeconomicsValues.currencies.pound}`, price: this.macroeconomicsValues.currencies.pound.toString()})
      }
    }

  }
  
  async getCocoaValuesFromAPI(){
    return await lastValueFrom(this.macroeconomicsService.getCocoa().pipe(
      (tap( response => {
      }

      ))
        ))
  }
  async setCocoaValues(){
    let cocoaPricesFromApi:any = await this.getCocoaValuesFromAPI()
    let keysToIterate = Object.keys(cocoaPricesFromApi)
    let currentValueExists = false
    for (let key of keysToIterate) {
      let cleanedPrice = cocoaPricesFromApi[key]["Último"].substring(0, cocoaPricesFromApi[0]["Último"].length-1)
      if(+this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON == +cleanedPrice) {currentValueExists=true}
      this.cocoaValues.push({label:`${cleanedPrice} ${cocoaPricesFromApi[key].Mes}` ,price: cleanedPrice})
    }
    if (!currentValueExists && +this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON>0) {
      this.cocoaValues.push({label: `${this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON} Cash`, price: (this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON??0).toString()})
    }
}

  async getPrices(dolarPrice:any, cocoaPrice:any, cocoaPremium:any){
    this.pricesLoaded=false;
    this.macroeconomicsService.getPrices(dolarPrice,cocoaPrice,cocoaPremium).subscribe(
      async (data:any) => { 
        this.tableData[0].pslicor = await Math.round(data.precioLicor).toString()
        this.tableData[0].psmanteca = await Math.round(data.precioManteca).toString()
        this.tableData[0].cacao = await Math.round(data.precioCacao).toString()
        this.tableData[0].pscocoa = await Math.round(data.precioCocoa).toString()
        this.tableData[0].psmantenca_deso = await Math.round(data.precioMantecaDeso).toString()
        this.tableData[0].nibs = await Math.round(data.psNibs).toString()
        // this.tableData[0].pslicor = this._decimalPipe.transform(data.precioLicor, '1.3-3')!
        // this.tableData[0].psmanteca = this._decimalPipe.transform(data.precioManteca, '1.3-3')!
        // this.tableData[0].cacao = this._decimalPipe.transform(data.porcentCacao, '1.3-3')!
        // this.tableData[0].pscacao = this._decimalPipe.transform(data.precioCacao, '1.3-3')!
        this.pricesLoaded=true;
        if (this.initial_all) {
          try {
            await this.setDolarValues();
            if (this.macroeconomicsValues.currencies.dolar>0) {
              this.currenciesForm.patchValue({
                dolar: this.macroeconomicsValues.currencies.dolar.toString(),
              });
            }
          } catch (error) {
            
          }
          try {
          await this.setEuroValues();
          if (this.macroeconomicsValues.currencies.euro>0) {
            this.currenciesForm.patchValue({
              euro: this.macroeconomicsValues.currencies.euro.toString(),
            });
          } else {
            this.currenciesForm.patchValue({
              euro: this.euroValues[0].price
            });
          }
          } catch (error) {
            
          }
          // await this.setPoundValues()  
          if (this.macroeconomicsValues.currencies.pound>0) {
            this.currenciesForm.patchValue({
              pound: this.macroeconomicsValues.currencies.pound.toString(),
            }, {emitEvent: this.macroeconomicsValues.currencies.dolar==0});
          }
          try {
            await this.setCocoaValues();
          } catch (error) {
            
          }
          this.pricesForm.patchValue(
            {
                cocoaPriceUSD_TON: this.macroeconomicsValues.cocoaPrices.cocoaPriceUSD_TON.toString()
            }
          )
          this.currenciesForm.get('dolar')?.enable();
          this.currenciesForm.get('euro')?.enable();
          // this.currenciesForm.get('pound')?.enable();
          this.pricesForm.get('cocoaPriceUSD_TON')?.enable();
          this.pricesForm.get('cocoaPremiumUSD_TON')?.enable();
        }
        this.initial=false
        this.initial_all=false
      },
      (error) => { 
        
      });
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  changeOtherDollarPrice(){
    this.otherDollarPrice=!this.otherDollarPrice
  }
  changeOtherEuroPrice(){
    this.otherEuroPrice=!this.otherEuroPrice
  }
  changeOtherPoundPrice(){
    this.otherPoundPrice=!this.otherPoundPrice
  }
  changeOtherCocoaPrice(){
    this.otherCocoaPrice=!this.otherCocoaPrice
  }

  changesUpdatesPopUp(){
    setTimeout( () => {
      if (this.success) {
        this.updateMacroeconomicsValues.emit(true);
        updateEditedStep(false)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Changes have been saved' });
      } else {
        this.updateMacroeconomicsValues.emit(false);
        this.messageService.add({ severity: 'success', summary: 'Fail', detail: 'Changes could not be saved' });
      }
    }, 300);
  }
  
}
