<p class="description">Please, fill out the following information in order to create the new folder.</p>
        <div class="container__basic-info">
            <p class="container__sesion-title">Basic Info</p>
            <form [formGroup]="basicInfoForm" id="container__form-base-info" action="">
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="projectname">Project Name <span style="color:red">*</span></label>
                                <input placeholder="Write here" (ngModelChange)="updateValues()" pInputText [formControlName]="'project_name'" id="projectname" aria-describedby="project-name-error" />
                                <small id="project-name-error" *ngIf="basicInfoForm.controls['project_name'].dirty && basicInfoForm.controls['project_name'].invalid"
                                        class="input-error">
                                    Please enter a project name
                                </small>
                                <small *ngIf="basicInfoForm.controls['project_name'].dirty && basicInfoForm.controls['project_name'].invalid">
                                    &nbsp;
                                </small>
                            </div>
                            
                        </div>
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="clientname">Client Name <span style="color:red">*</span></label>
                                <input *ngIf="newClient" placeholder="Write here" (ngModelChange)="updateValues()" pInputText [formControlName]="'client_name'" id="clientname" aria-describedby="client-error" />
                                <p-dropdown *ngIf="!newClient" formControlName="client" 
                                (ngModelChange)="updateValues()" 
                                styleClass="business-unit-dropdown" 
                                optionLabel="name" 
                                optionValue="_id" 
                                placeholder="{{newClient?'Select or type to create a new client':'Selected a client'}}" 
                                [editable]="false" 
                                [filter]="true"
                                filterBy="name"
                                [options]="clients">
                                </p-dropdown>
                                <div (click)="createNewClient()" class="container__other-price">
                                    <small>{{!newClient?"Create new client":"Selected client from list"}}</small>
                                </div>
                                <small id="client-name-error" *ngIf="basicInfoForm.controls['client'].dirty && basicInfoForm.controls['client'].invalid"
                                        class="input-error">
                                        Please enter a client name
                                </small>
                                <small *ngIf="basicInfoForm.controls['client'].dirty && basicInfoForm.controls['client'].invalid">
                                    &nbsp;
                                </small>
                            </div>
                        </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="executive">Executive</label>
                                <p-dropdown formControlName="executive" 
                                (ngModelChange)="updateValues()" 
                                styleClass="business-unit-dropdown" 
                                optionValue="_id" 
                                optionLabel="nombre_completo" 
                                placeholder="Select the User to Assign" 
                                [options]="executiveOptions"
                                [filter]="true"
                                filterBy="nombre_completo"
                                >
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ selectedExecutive }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="salesvolume">Sales Volume (Kg)</label>
                                <input placeholder="Write here" appOnlyNumber (ngModelChange)="updateValues()" [formControlName]="'sales_volume'" pInputText id="salesvolume" aria-describedby="sales-volume-error" />
                                <small id="sales-volume-error" *ngIf="basicInfoForm.controls['sales_volume'].dirty && basicInfoForm.controls['sales_volume'].invalid"
                                        class="input-error">
                                        This field must not be empty
                                </small>
                                <small *ngIf="(basicInfoForm.controls['sales_volume'].dirty && basicInfoForm.controls['sales_volume'].invalid)">
                                    &nbsp;
                                </small>
                            </div>
                        </div>
                </div>
                <div *ngIf="updatingFolder" class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="bussinesunit">Business Unit</label>
                            <p-dropdown formControlName="bussines_unit" (ngModelChange)="updateValues(); changeBusinessUnit()" styleClass="business-unit-dropdown" [options]="businessUnitOptions" optionLabel="name" optionValue="_id"></p-dropdown>
                        </div>
                    </div>
                    <div class=" container__input">
                    </div>
                </div>
            </form>
            <p class="container__sesion-title">Macroeconomics</p>
            <form [formGroup]="macroeconomicsForm" class="form-group" id="container__form-macroeconomics" action="">
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__currency">
                        <div class="">
                            <label class="currency" htmlFor="dolar">Dolar</label>
                            <p-dropdown *ngIf="!otherDollarPrice" formControlName="dollar_price" (ngModelChange)="updateValues()" styleClass="business-unit-dropdown" [placeholder]="'Select a dolar price'"  optionLabel="label" optionValue="price" [options]="dolarValues">
                            </p-dropdown>
                            <input *ngIf="otherDollarPrice" appTwoDigitDecimaNumber type="text" min="0" (ngModelChange)="updateValues()" [formControlName]="'dollar_price'" pInputText id="clientname" aria-describedby="username-help"
                            placeholder="Write here" />
                            <div *ngIf="!macroeconomicsForm.controls['dollar_price'].disabled" (click)="changeOtherDollarPrice()" class="container__other-price">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                    </div>
                    <div class=" container__currency">
                        <div class="">
                            <label class="currency" htmlFor="dolar">Euro</label>
                            <p-dropdown formControlName="euro_price" (ngModelChange)="updateValues()" styleClass="business-unit-dropdown" [placeholder]="'Select an euro price'"  optionLabel="label" optionValue="price">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class=" container__currency">
                        <div class="">
                            <label htmlFor="pound">Pound</label>
                            <p-dropdown *ngIf="!otherPoundPrice" formControlName="pound_price" (ngModelChange)="updateValues()" styleClass="business-unit-dropdown" placeholder="Select a pound price" [options]="poundValues" optionLabel="label" optionValue="price">
                            </p-dropdown>
                            <input *ngIf="otherPoundPrice" appTwoDigitDecimaNumber type="text" min="0" (ngModelChange)="updateValues()" [formControlName]="'pound_price'" pInputText id="clientname" aria-describedby="username-help"
                            placeholder="Write here" />
                            <!-- <div class="container__other-price">
                                <small>Enter Other Price</small>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__input">
                        <div class="">
                            <label class="currency" htmlFor="cocoa_price">Cocoa Price USD/Ton</label>
                            <p-dropdown *ngIf="!otherCocoaPrice" formControlName="cocoa_price" (ngModelChange)="updateValues()" styleClass="business-unit-dropdown" [placeholder]="'Select a cocoa price'"  optionLabel="label" optionValue="price" [options]="cocoaValues">
                            </p-dropdown>
                            <input *ngIf="otherCocoaPrice" appTwoDigitDecimaNumber type="text" min="0" (ngModelChange)="updateValues()" [formControlName]="'cocoa_price'" pInputText id="clientname" aria-describedby="username-help"
                            placeholder="Write here" />
                            <div *ngIf="!macroeconomicsForm.controls['cocoa_price'].disabled" (click)="changeOtherCocoaPrice()" class="container__other-price">
                                <small>Enter Other Price</small>
                            </div>
                        </div>
                    </div>
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="salesvolume">Cocoa Premium USD/Ton</label>
                            <input placeholder="Write here" appTwoDigitDecimaNumber (ngModelChange)="updateValues()" [formControlName]="'cocoa_premium'" pInputText id="cocoapremium" aria-describedby="cocoa-premium-error" />
                            <!-- <small id="cocoa-premium-error" *ngIf="macroeconomicsForm.controls['cocoa_premiun'].dirty && macroeconomicsForm.controls['cocoa_premium'].invalid"
                                    class="input-error">
                                    This field must not be empty
                            </small> -->
                            <!-- <small *ngIf="(macroeconomicsForm.controls['cocoa_premium'].dirty && macroeconomicsForm.controls['cocoa_premium'].invalid)">
                                &nbsp;
                            </small> -->
                        </div>
                    </div>
                </div>
            </form>
            <p class="container__sesion-title">Assign users</p>
            <form [formGroup]="usersForm" id="container__form-base-info" action="">
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="rAndD">R&D</label>
                                <p-dropdown formControlName="rAndD" 
                                (ngModelChange)="updateValues()" 
                                styleClass="business-unit-dropdown" 
                                optionValue="_id" 
                                optionLabel="nombre_completo" 
                                placeholder="Select the User to Assign" 
                                [options]="rAndDUsers"
                                [filter]="true"
                                filterBy="nombre_completo"
                                >
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ userAssignedNameRandD }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="packagingg">Packaging</label>
                                <p-dropdown formControlName="packaging" 
                                (ngModelChange)="updateValues()" 
                                styleClass="business-unit-dropdown" 
                                optionValue="_id" 
                                optionLabel="nombre_completo" 
                                placeholder="Select the User to Assign" 
                                [options]="packagingUsers"
                                [filter]="true"
                                filterBy="nombre_completo"
                                >
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ userAssignedNamePackaging }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                        <div class=" container__input">
                            <div class="">
                                <label htmlFor="processes">Processes</label>
                                <p-dropdown formControlName="processes" 
                                (ngModelChange)="updateValues()" 
                                styleClass="business-unit-dropdown" 
                                optionValue="_id" 
                                optionLabel="nombre_completo" 
                                placeholder="Select the User to Assign" 
                                [options]="processesUsers"
                                [filter]="true"
                                filterBy="nombre_completo"
                                >
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ userAssignedNameProcesses }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class=" container__input">
                        </div>
                </div>
            </form>
            <p class="container__sesion-title">Delivery</p>
            <form [formGroup]="deliveryForm" class="form-group" id="container__form-delivery" action="">
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="destinatiocountry">Destination  Country</label>
                            <input placeholder="Write here" (ngModelChange)="updateValues()" [formControlName]="'destination_country'" pInputText id="destinatiocountry" aria-describedby="destination-country-error" />
                        </div>
                        
                    </div>
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="incoterm">Incoterm</label>
                            <input placeholder="Write here" (ngModelChange)="updateValues()" [formControlName]="'incoterm'" pInputText id="incoterm" aria-describedby="inconterm-error" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="deliveryport">Delivery Port</label>
                            <input placeholder="Write here" (ngModelChange)="updateValues()" [formControlName]="'delivery_port'" pInputText id="deliveryport" aria-describedby="delivery-port-error" />
                        </div>
                        
                    </div>
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="address">Address</label>
                            <input placeholder="Write here" (ngModelChange)="updateValues()" [formControlName]="'address'" pInputText id="address" aria-describedby="address-error" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap justify-between">
                    <div class=" container__input">
                        <div class="">
                            <label htmlFor="city">City</label>
                            <input placeholder="Write here" (ngModelChange)="updateValues()" [formControlName]="'city'" pInputText id="city" aria-describedby="city-error" />
                        </div>
                    </div>
                    <div class=" container__input">
                        <div class="">

                        </div>
                    </div>
                </div>
            </form>
            <p class="container__sesion-title">Comments</p>
            <form [formGroup]="commentsForm" id="container__form-base-info" action="">
                <div class="flex flex-wrap lg:flex-nowrap mb-3">
                    <div class="w-full">
                        <textarea placeholder="Input label" [maxlength]="250" [formControlName]="'comments'" (ngModelChange)="updateValues()" id="packaging" rows="5" cols="30" pInputTextarea></textarea>
                        <div class="container__feed-comment mt-1">
                            <small>250 characters max.</small>
                        </div>
                    </div>
                </div>
            </form>
            <div class="flex justify-content-end container__button-create-folder">
                <p-button  *ngIf="!updatingFolder" (onClick)="createFolderButton()" [disabled]="!createUpdateFolder || (newClient && this.folderValues.basic_info.client_name=='') || (!newClient && this.folderValues.basic_info.client=='')" label="Create Folder"></p-button>
                <p-button  *ngIf="updatingFolder" (onClick)="updateFolderButton()" [disabled]="!createUpdateFolder || (newClient && this.folderValues.basic_info.client_name=='') || (!newClient && this.folderValues.basic_info.client=='')" label="Update Folder"></p-button>
            </div>
            
        </div>